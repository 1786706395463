import React from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';

export function datasetConfirmationDialogFunc(confirmationOpen, handleClose, makeDatasetPublic) {
    return (value) => {
        return <Dialog
            open={confirmationOpen}
            onClose={handleClose}
        >
            <DialogTitle>
                Confirmation
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you willing to make <b> {value} </b> dataset public?
                    <br></br>
                    <br></br>
                    <b>Note:This action cannot be undone.</b>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={makeDatasetPublic} autoFocus>
                    Agree
                </Button>
            </DialogActions>

        </Dialog>;
    };
}

export function modelConfirmationDialogFunc(confirmationOpen, handleClose, makeModelPublic) {
    return (value) => {
        return <Dialog
            open={confirmationOpen}
            onClose={handleClose}
        >
            <DialogTitle>
                Confirmation
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you willing to make <b> {value}  </b>model public ?
                    <br></br>
                    <br></br>
                    <b>Note:This action cannot be undone.</b>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={makeModelPublic} autoFocus>
                    Agree
                </Button>
            </DialogActions>

        </Dialog>;
    };
}

export function modelDeleteDialogFunc(confirmationOpen, handleClose) {
    return (value) => {
        return <Dialog
            open={confirmationOpen}
            onClose={handleClose}
        >
            <DialogTitle>
                Confirmation
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you willing to delete permanently ?
                    <br></br>
                    <br></br>
                    <b>Note:This action cannot be undone.</b>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={handleClose} autoFocus>
                    Agree
                </Button>
            </DialogActions>

        </Dialog>;
    };
}