import { Button, Card, CardActions, CardContent, CardHeader, Checkbox, Grid, TextField, Typography } from '@material-ui/core';
import { Alert } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import validator from 'validator'


async function loginUser(credentials) {

    return fetch('/api/token/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

export default function Login({ setToken }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [failAlert, setFailAlert] = useState(false);
    const [failMessage, setFailMessage] = useState("Wrong Username or Password");

    let navigate = useNavigate();

    async function handleSubmit(event) {

        event.preventDefault();

        if (!validator.isEmail(username)) {
            event.target.reset();
            setFailMessage('Invalid Email ... Please enter a valid email')
            setFailAlert(true);
            return;
        }

        const response = await loginUser({
            username,
            password
        });


        if (!response.detail === '') {
            event.target.reset();
            setFailMessage(response.detail)
            setFailAlert(true);
            return;
        }

        setToken(response.access);
        sessionStorage.setItem('token', response.access);
        navigate("/introduction");
    }

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    return (
        <>
            <div>
                <form onSubmit={handleSubmit}>
                    <Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '80vh' }}>
                        <Grid item xs={12}>
                            <Card style={{
                                boxShadow: ' 0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)',
                            }}>
                                <CardHeader title={<Typography component={'span'} variant={"h3"} style={{
                                    color: '#2A3F54',
                                    fontWeight: 'bold'
                                }}>Sign in</Typography>} />
                                <CardContent >
                                    <Typography component={'span'} style={{
                                        color: '#2A3F54'
                                    }} sx={{ fontSize: 14 }} color="secondary" >
                                        Sign in and start performing analysis..!!
                                    </Typography><br></br><br></br>
                                    <Typography component={'span'} >
                                        <TextField label="Email" fullWidth required variant="outlined" onChange={e => setUsername(e.target.value)} />
                                    </Typography><br></br><br></br>
                                    <Typography component={'span'}>
                                        <TextField type="password" label="Password" fullWidth required variant="outlined" onChange={e => setPassword(e.target.value)} />
                                    </Typography>
                                    <Typography component={'span'} display="inline" style={{ float: 'left' }}>
                                        <Checkbox {...label} />Remember me
                                    </Typography>
                                    <Typography component={'span'} display="inline" style={{ float: 'right' }}>
                                        <Link to='/forgot_password'>Forgot Password?</Link>
                                    </Typography>
                                </CardContent>
                                <br></br><br></br>
                                <CardActions>
                                    <Button style={{
                                        margin: 'auto',
                                        backgroundColor: '#2A3F54',
                                        color: 'white'
                                    }} type="submit" size="large" >Login</Button>
                                </CardActions>
                            </Card>
                            {failAlert ? (<Alert severity="error">{failMessage}</Alert>) : ''}
                            <br></br>
                            <Typography component={'span'} display="inline">
                                Create an account here <Link to="/signup">Signup</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </form>
            </ div>
        </>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};