import React from 'react';
import { Link } from 'react-router-dom';
import { Chip } from '@mui/material';
import { datasetTypeRepresentationBlock } from './datasetTypeRepresentationBlock';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Plot from 'react-plotly.js';
import { useEffect, useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export const datasetTableColumns = [
    // {
    //     title: 'Dataset Name', render: rowData => <Link to='/dataset_info' state={{ row: rowData }}> {rowData.dataset_name}</Link>,
    // },
    {
        title: 'Dataset Name', field: 'dataset_name'
    },
    { title: '# Samples', field: 'num_samples' },
    { title: '# Features', field: 'num_features' },
    { title: 'Label Type', field: 'label_type_name' },
    { title: 'Datasource Type', field: 'datasource_type' },
    { title: 'Tags', field: 'tags' },
    { title: 'Created at', field: 'created_at' },
];

export const datasetPublicTableMbColumns = [

    {
        label: 'Dataset Name', field: 'dataset_name'
    },
    { label: 'Dataset Type', field: 'dataset_type_key' },
    { label: '# Samples', field: 'num_samples' },
    { label: '# Features', field: 'num_features' },
    { label: 'Label Type', field: 'label_type_name' },
    { label: 'Datasource Type', field: 'datasource_type' },
    { label: 'Tags', field: 'tags' },
    { label: 'Created at', field: 'created_at' },
];

export const datasetPrivateTableMbColumns = [

    {
        label: 'Dataset Name', field: 'dataset_name'
    },
    { label: 'Dataset Type', field: 'dataset_type_key' },
    { label: '# Samples', field: 'num_samples' },
    { label: '# Features', field: 'num_features' },
    { label: 'Label Type', field: 'label_type_name' },
    { label: 'Datasource Type', field: 'datasource_type' },
    { label: 'Tags', field: 'tags' },
    { label: 'Created at', field: 'created_at' },
    { label: 'Action', field: 'button', sort: 'disabled' }
];

export const datasetTableColumnsTest = [
    {
        name: 'Dataset Name', selector: 'dataset_name', sortable: true,
    },
    { name: '# Samples', selector: 'num_samples', sortable: true, },
    { name: '# Features', selector: 'num_features', sortable: true, },
    { name: 'Label Type', selector: 'label_type_name', sortable: true, },
    { name: 'Datasource Type', selector: 'datasource_type', sortable: true, },
    { name: 'Tags', selector: 'tags', sortable: true, },
    { name: 'Created at', selector: 'created_at', sortable: true, },
];

export const projectTableColumns = [
    {
        title: 'Project Name', field: 'project_name', render: rowData => <Link to='/project_info' state={{ row: rowData }}> {rowData.project_name}</Link>,
    },
    { title: 'Model Name', field: 'ml_model_info.model_name' },
    // { title: 'Created Time', field: 'created_at' },
    // { title: 'Updated Time', field: 'updated_at' },
    { title: 'Description', field: 'description' },
    {
        title: 'Status', field: 'task_info.status', render: rowData => <>
            {rowData.task_info.status == "PENDING" && (<Chip label={rowData.task_info.status} color='default' />)}
            {rowData.task_info.status == "SUCCESS" && (<Chip label={rowData.task_info.status} color='success' />)}
            {rowData.task_info.status == "RUNNING" && (<Chip label={rowData.task_info.status} color='info' />)}
            {rowData.task_info.status == "FAILURE" && (<Chip label={rowData.task_info.status} color='error' />)}
        </>
    },
    { title: 'Percentage completed', field: 'task_info.percentage' },
];

export const projectMdbTableColumns = [
    {
        label: 'Project Name', field: 'project_name'
    },
    { label: 'Model Name', field: 'modelName' },

    { label: 'Dataset Types', field: 'datasetType' },

    {
        label: 'Status', field: 'taskStatus'
    },
    { label: 'Updated Time', field: 'updated_at' },

];

export const modelTableColumns = [
    { title: 'Model Name', field: 'model_name', type: 'json' },
    { title: 'Dataset Type', field: 'dataset_type_name_vlsv' },
    { title: 'Label Type', field: 'label_type_name' },
    { title: 'Created Time', field: 'created_at' },
];

export const modelPublicMdbTableColumns = [
    { label: 'Model Name', field: 'model_name' },
    { label: 'Dataset Type', field: 'dataset_type_key_vlsv' },
    { label: 'Hyperparameter Mode', field: 'ml_model_hparam_mode_key' },
    { label: 'Label Type', field: 'label_type_name' },
    { label: 'Created Time', field: 'created_at' },
];

export const modelPrivateMdbTableColumns = [
    { label: 'Model Name', field: 'model_name' },
    { label: 'Dataset Type', field: 'dataset_type_key_vlsv' },
    { label: 'Hyperparameter Mode', field: 'ml_model_hparam_mode_key' },
    { label: 'Label Type', field: 'label_type_name' },
    { label: 'Created Time', field: 'created_at' },
    { label: 'Action', field: 'button', sort: 'disabled' }
];

export const predictionInfoTablecolumns = [

    { title: 'Term', field: 'Term' },
    { title: 'Overlap', field: 'Overlap' },
    { title: 'P-value', field: 'P-value' },
    { title: 'Genes', field: 'Genes' },
    { title: 'Combined Score', field: 'Combined Score' }
];

export const studyresultsColumns = [

    {
        title: 'Dataset Type', field: 'dataset_type_names', render: (rowData) => {

            let temp = [];
            let values = rowData.dataset_type_names.split("&");
            values.map(element => {
                temp.push(datasetTypeRepresentationBlock(datasetTypeKeyValues[element.trim()]));
            });
            return temp;
        }
    },
    { title: 'DNA Methylation', field: 'DNA_METHYLATION' },
    { title: 'Gene Expression', field: 'GENE_EXPRESSION' },
    { title: 'SNP', field: 'SNP' },
    { title: 'CNV', field: 'CNV' },
    { title: 'Protien Expression', field: 'PROTEIN_EXPRESSION' },
    { title: 'MiRNA Expression', field: 'MIRNA_EXPRESSION' },
];

export const resultsTableColumns = [
    {
        headerName: "Dataset type",
        field: "dataset",
        RenderCell: (params) => {
            let temp = [];
            let values = params.value.split("|");

            values.map(element => {
                let value = datasetTypeKeyValues[element.trim()];
                let color = datasetTypeKeyColors[element.trim()];
                temp.push(datasetTypeRepresentationBlock(value));
                // temp.push(datasetTypeRepresentationBlock(datasetTypeKeyValues[element.trim()], datasetTypeKeyColors[element.trim()]));
            });
            return temp;
        },
        flex: 1
    },
    { headerName: "CNN", field: "CNN", flex: 1 },
    { headerName: "SVM", field: "SVM", flex: 1 },
    { headerName: "Decision Tree", field: "Decision tree", flex: 1 },
    { headerName: "Logistic Regression", field: "Logistic regression", flex: 1 }
];


export const bioMarkersTableColumns = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 1,
    },
    {
      headerName: 'Sets',
      field: 'sets',
      flex: 1,
    },
    {
      headerName: 'Pathways',
      field: 'pathways',
      flex: 1,
    },
    {
      headerName: 'Up/Down Regulated',
      field: 'up/down regulated',
      flex: 1,
      RenderCell: (params) => {
        const [open, setOpen] = useState(false);
  
        const handleOpen = () => {
          setOpen(true);
        };
  
        const handleClose = () => {
          setOpen(false);
        };
      const ltsData = [0.6, 0.7, 0.1];
      const nonLtsData = [0.2, 0.4, 0.5];

        return (
          <div>
           <Button onClick={handleOpen}>Up/Down</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          LTS and NON-LTS
          </Typography>
          <Plot
                data={[
                  {
                    y: ltsData,
                    type: 'box',
                    name: 'LTS',
                  },
                  {
                    y: nonLtsData,
                    type: 'box',
                    name: 'NON-LTS',
                  },
                ]}
                layout={{ title: 'Box Plot' }}
              />
        </Box>
      </Modal>
          </div>
        );
      },
    },
  ];


export const datasetTypeKeyValues = {
    'DNA_METHYLATION': 'D',
    'GENE_EXPRESSION': 'G',
    'CNV': 'C',
    'SNP': 'S',
    'PROTEIN_EXPRESSION': 'P',
    'MIRNA_EXPRESSION': 'M',
    'ZYGOSITY':'Z'
};


export const datasetTypeKeyColors = {
    'DNA_METHYLATION': '#C6EB98 !important',
    'GENE_EXPRESSION': '#FFC69D !important',
    'CNV': '#DDE5FE !important',
    'SNP': '#fce6e6 !important',
    'PROTEIN_EXPRESSION': '#9EFFC8 !important',
    'MIRNA_EXPRESSION': '#FFEC9E !important',
};

export const precictionMdbTableColumns = [
    { label: 'Prediction Name', field: 'prediciton_name' },
    { label: 'Project Name', field: 'projectname' },
    { label: 'Description', field: 'description' },
    { label: 'Created Time', field: 'created_at' },
];

