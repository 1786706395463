import { Card, CardActions, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from "react-router-dom";
import PrivateTestModel from './PrivateTestModel';
import PublicTestModel from './PublicTestModel';

export default function Models({ setToken }) {

    let navigate = useNavigate();

    const token = sessionStorage.getItem('token');

    const loginPageRedirect = () => {
        navigate("/login");
    }

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={6} >
                    <div style={{ textAlign: "justify" }}>
                        <h3> Summary of Models</h3>
                        <p>Instruction:</p>
                        <ul>
                            <li>The publicly available models are trained with TCGA dataset.</li>
                            <li>The private model can be created and trained after <Link to="/login" > login</Link>.</li>
                        </ul>
                        <br></br>
                        <br></br>
                    </div>
                </Grid>
                {token != null ?
                    <Grid item xs={6} >
                        <Link to='/create_model'>
                            <button type='button' style={{
                                margin: '10%',
                                padding: '5px',
                                backgroundColor: '#2A3F54',
                                color: 'white',
                                float: 'right',
                                borderRadius: '5px'
                            }}>MODEL CREATION</button>
                        </Link>

                    </Grid>
                    : ''}
            </Grid>

            <div>
                <PublicTestModel />
            </div>
            <br></br>
            <br></br>
            <div>
                {token != null ? (
                    <PrivateTestModel setToken={setToken} />
                ) : (
                    <Card style={{ border: '1px solid' }}>
                        <CardHeader title={<Typography variant={"h6"} style={{
                            float: 'left'
                        }}>My Models Summary</Typography>} />
                        <Divider></Divider>
                        <CardContent> Please login to see your Model information</CardContent>
                        <CardActions>
                            {/* <Link to="/login"> */}
                            <Button style={{
                                margin: 'auto',
                                backgroundColor: '#2A3F54',
                                color: 'white'
                            }} onClick={loginPageRedirect} >Login</Button>
                            {/* </Link> */}
                        </CardActions>
                    </Card>)}
            </div>

        </>
    )
}
