import { Card, CardActions, CardContent } from '@mui/material';
import TruptiJoshiImage from './Images/Trupti-Joshi.jpg';
import AjayImage from './Images/Ajay-Kumar.jpg';
import ShuaiImage from './Images/Shuai-Zeng.jpg';
import TrinathImage from './Images/Trinath.jpg';
import ManishImage from './Images/Manish.jpg'
import DongXuImage from './Images/Dong-Xu.jpg';
import SaniaImage from './Images/Sania.jpeg';

function ContactInfo() {
    const faculty_card_css = { width: '30%', margin: '1%', boxShadow: '0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)' };
    const dev_card_css = { width: '23%', margin: '1%', boxShadow: '0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)' };
    const img_css = { width: '250px', height: '250px' };
    const heading_css = {
        margin: '10px',
        fontWeight: 500,
        color: 'white',
        background: '#2A3F54',
        padding: '10px'
    };
    const email_css = {
        background: '#4A4A4A',
        padding: '8px',
        color: 'white',
        fontWeight: 'bolder'
    };
    const name_css = { fontWeight: 400, margin: '5px' };
    return (
        <>
            <h3 style={heading_css}>Faculty</h3>
            <div className="header" style={{ display: 'flex', justifyContent:'center' }}>

                {/* <Card style={faculty_card_css}>
                    <CardContent>
                        <div >
                            <div>
                                <img
                                    decoding="async"
                                    src={DongXuImage}
                                    alt="Dong Xu"

                                    style={img_css}
                                />
                            </div>
                            <h3 style={name_css}>Dong Xu</h3>
                            <p >Curators’ Distinguished Professor Fellow, AAAAS, AIMBE Core Faculty, LAS, IDSI, IPG</p>
                            <p >Department of Electrical Engineering and Computer Science</p>
                            <a style={email_css} href="mailto:XuDong@missouri.edu" target="_blank"> Email Dong</a>
                        </div >
                    </CardContent>
                </Card > */}
                <Card style={faculty_card_css}>
                    <CardContent>
                        <div >
                            <div>
                                <img
                                    decoding="async"
                                    src={TruptiJoshiImage}
                                    alt="Trupti Joshi"

                                    style={img_css}
                                />
                            </div>
                            <h3  style={name_css}>Trupti Joshi</h3>
                            <p>Assistant Professor</p>
                            <p>Core Faculty, IDSI, IPG, LSC, EECS</p>
                            <p>Department of Health Management and Informatics</p>
                            <a style={email_css} href="mailto:JoshiTr@missouri.edu" target="_blank"> Email Trupti</a>
                        </div >
                    </CardContent>
                </Card >
            </div>
            <h3 style={heading_css}> Developers</h3>
            <div style={{ display: 'flex', justifyContent:'center' }}>

                <Card style={dev_card_css}>
                    <CardContent>
                        <div >
                            <div>
                                <img
                                    decoding="async"
                                    src={ShuaiImage}
                                    alt="Shuai Zeng"

                                    style={img_css}
                                />
                            </div>
                            <h3  style={name_css}>Shuai Zeng</h3>
                            <p>Post Doctoral Fellow </p>
                            <p>Department of Electrical Engineering and Computer Science</p>
                            <a style={email_css} href="mailto:zengs@mail.missouri.edu" target="_blank"> Email Shuai</a>
                        </div >
                    </CardContent>
                </Card >
                <Card style={dev_card_css}>
                    <CardContent>
                        <div >
                            <div>
                                <img
                                    decoding="async"
                                    src={SaniaImage}
                                    alt="Sania zafar"

                                    style={img_css}
                                />
                            </div>
                            <h3 style={name_css} >Sania Zafar Awan</h3>
                            <p >Ph.D. student </p>
                            <p >MU Institute for Data Science and Informatics</p>
                            <br></br>
                            <a style={email_css} href="mailto:sah2p@missouri.edu" target="_blank"> Email Sania</a>
                        </div >
                    </CardContent>
                </Card >
                <Card style={dev_card_css}>
                    <CardContent>
                        <div >
                            <div>
                                <img
                                    decoding="async"
                                    src={ManishImage}
                                    alt="Manish Sridhar Immadi"

                                    style={img_css}
                                />
                            </div>
                            <h3 style={name_css} >Manish Sridhar</h3>
                            <p >M.S. Student</p>
                            <p >Department of Electrical Engineering and Computer Science</p>
                            <a style={email_css} href="mailto:mizy9@missouri.edu" target="_blank"> Email Manish</a>
                        </div >
                    </CardContent>
                </Card >
                <Card style={dev_card_css}>
                    <CardContent>
                        <div >
                            <div>
                                <img
                                    decoding="async"
                                    src={TrinathImage}
                                    alt="Trinath Adusumilli"

                                    style={img_css}
                                />
                            </div>
                            <h3 style={name_css} >Trinath Adusumilli</h3>
                            <p >M.S. Student</p>
                            <p >Department of Electrical Engineering and Computer Science</p>
                            {/* <a style={email_css} href="mailto:tafbr@missouri.edu" target="_blank"> Email Trinath</a> */}
                        </div >
                    </CardContent>
                </Card >
            </div>
        </>
    )
}

export default ContactInfo