import { useEffect, useRef, useState } from 'react';

import { Chip, LinearProgress } from '@material-ui/core';
import { MDBDataTableV5 } from "mdbreact";
import { useNavigate } from 'react-router';
import { datasetTypeRepresentationBlock } from '../util/datasetTypeRepresentationBlock';
import { StudyresultsLegendsDiv } from '../util/LegendsDiv';
import { projectMdbTableColumns } from '../util/tableColumnsDetails';
import { logoutFakeUser } from '../Auth/logoutFakeUser';

export default function PrivateTestProjectTable({ setToken }) {

    const [projectInfo, setProjectInfo] = useState([]);

    let navigate = useNavigate();

    const token = sessionStorage.getItem('token');

    const didMount = useRef(true);

    var timer;

    useEffect(() => {
        timer = setInterval(() => {
            getPrivateProjectInfo();
        }, 15000);
        return () => clearTimeout(timer);
    }, [])

    useEffect(() => {

        if (didMount.current) {
            getPrivateProjectInfo();
            didMount.current = false;
        }

    }, [])

    const getPrivateProjectInfo = () => {

        fetch(`/api/operation/retrieve_project_info`,
            {
                headers: { "Authorization": `Bearer ${token}` }
            })
            .then(res => res.json())
            .then(data => {

                if (!data.detail == '') {
                    logoutFakeUser(setToken, navigate);
                }

                setProjectInfo(data.message);
                return data;
            })
    }

    const statusValue = (status, percentage) => {
        if (status == "PENDING") {
            return (<><Chip label={status} style={{ backgroundColor: '#a73b3b', color: 'white' }} /> <LinearProgress variant="determinate" value={0} style={{ margin: '5px' }} /></>)
        }
        if (status == "SUCCESS") {
            return (<> <Chip label={status} style={{ backgroundColor: '#9bf89b', color: 'black' }} /></>)
        }
        if (status == "RUNNING") {
            return (<><Chip label={status} style={{ backgroundColor: '#b0f5f5', color: 'black' }} /> <LinearProgress variant="determinate" value={percentage} style={{ margin: '5px' }} /></>)
        }
        if (status == "FAILURE") {
            return (<><Chip label={status} style={{ backgroundColor: '#ff5858', color: 'black' }} /></>)
        }
    }


    const getDatasetTypes = (data) => {
        let temp = [];
        let values = data.split("|");
        values.map(element => {
            temp.push(datasetTypeRepresentationBlock(element.substring(0, 1)));
        });
        return temp;
    }

    const handleRowClick = (rowData) => {
        navigate(`/project_info`, { state: { row: rowData } })
    };

    const addDataToRows = (rows) => {
        if (!Array.isArray(rows) || rows.length === 0) {
            return []; // Return an empty array if 'rows' is not valid
        }
        return rows.map((row) => {
            const datasetTypeList = row.ml_model_info?.dataset_type_list || []; // Default to an empty array if 'dataset_type_list' is missing
            return {
                ...row,
                datasetType: getDatasetTypes(row.ml_model_info.dataset_type_name_vlsv),
                modelName: row.ml_model_info.model_name,
                taskStatus: statusValue(row.task_info.status, row.task_info.percentage),
                clickEvent: () => handleRowClick(row),
            };
        });
    };

    return (
        <div className="App wrapper" style={{
            padding: '1%',
            boxShadow: ' 0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)'
        }}>

            <h4 style={{
                fontWeight: '400',
                color: 'rgb(42, 63, 84)',
                textAlign: 'left'
            }}> Projects Summary</h4>

            <MDBDataTableV5
                hover
                bordered
                striped
                small
                entriesOptions={[5, 10, 15, 20]}
                entries={5}
                data={{ columns: projectMdbTableColumns, rows: addDataToRows(projectInfo) }}
            />
            <p style={{ color: 'red', fontWeight: 'bold', textAlign: 'left' }}>* Auto refresh in every 15 seconds</p>
            {StudyresultsLegendsDiv}

        </div>
    )
}
