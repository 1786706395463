import { Button, Grid, Slider, Typography } from '@mui/material';
import { CreateModelLegendsDiv } from '../util/LegendsDiv';
import arrowInput from './arrow-input.png';
import cnn_green from './cnn_green.png';
import cnn_yellow from './cnn_yellow.png';
import csvImg from './csv_file.png';
import dnn_layer from './dnn_layer.png';
import dnn_output from './dnn_output.png';

export default function DualCNNHyperParams({ hyperParamvalues, setHyperParamvalues }) {

  const addTower1 = () => {

    if (hyperParamvalues.tower1Num < hyperParamvalues.maxLayerNum) {
      const temp = [...hyperParamvalues.tower1];
      temp.push(
        <div key={hyperParamvalues.tower1Num}>
          <img src={arrowInput} style={{ height: '20px', marginLeft: '340px', marginBottom: '10px' }}></img>
          <div>
            <Slider
              name='tower1_filters[]'
              style={{ color: '#1165f1', width: '100px' }}
              defaultValue={5}
              aria-labelledby="discrete-slider-small-steps"
              min={3} max={12} step={1}
              valueLabelDisplay="on"
            />
            <Slider
              name='tower1_kernel_size[]'
              style={{ color: 'rgba(239, 28, 65, 0.97)', width: '100px', marginLeft: '30px' }}
              defaultValue={10}
              aria-labelledby="discrete-slider-small-steps"
              min={4} max={30} step={1}
              valueLabelDisplay="on"
            />
            <img src={cnn_yellow} style={{ width: '150px', marginLeft: '50px', marginTop: '-20px' }}></img>
          </div>
        </div>
      )

      setHyperParamvalues((hyperParamvalues) => ({
        ...hyperParamvalues,
        tower1: temp,
        tower1Num: hyperParamvalues.tower1Num + 1,
      }))
    }
  }

  const delTower1 = () => {
    let temp = [...hyperParamvalues.tower1];
    temp.pop();
    setHyperParamvalues((hyperParamvalues) => ({
      ...hyperParamvalues,
      tower1: temp,
      tower1Num: hyperParamvalues.tower1Num - 1
    }))
  }

  const addTower2 = () => {
    if (hyperParamvalues.tower2Num < hyperParamvalues.maxLayerNum) {
      let temp = [...hyperParamvalues.tower2];
      temp.push(
        <div key={hyperParamvalues.tower2Num}>
          <img src={arrowInput} style={{ height: '20px', marginLeft: '60px', marginBottom: '10px' }}></img>
          <div>
            <img src={cnn_green} style={{ width: '150px', marginTop: '-20px' }}></img>
            <Slider
              name='tower2_filters[]'
              style={{ color: '#1165f1', width: '100px', marginLeft: '45px' }}
              defaultValue={5}
              aria-labelledby="discrete-slider-small-steps"
              min={3} max={12} step={1}
              valueLabelDisplay="on"
            />
            <Slider
              name='tower2_kernel_size[]'
              style={{ color: 'rgba(239, 28, 65, 0.97)', width: '100px', marginLeft: '30px' }}
              defaultValue={10}
              aria-labelledby="discrete-slider-small-steps"
              min={4} max={30} step={1}
              valueLabelDisplay="on"
            />
          </div>
        </div>
      )
      setHyperParamvalues((hyperParamvalues) => ({
        ...hyperParamvalues,
        tower2: temp,
        tower2Num: hyperParamvalues.tower2Num + 1
      }))
    }
  }

  const delTower2 = () => {
    let temp = [...hyperParamvalues.tower2];
    temp.pop();
    setHyperParamvalues((hyperParamvalues) => ({
      ...hyperParamvalues,
      tower2: temp,
      tower2Num: hyperParamvalues.tower2Num - 1
    }))
  }

  const delFCn = () => {

    let temp = [...hyperParamvalues.fCn];
    temp.pop();
    setHyperParamvalues((hyperParamvalues) => ({
      ...hyperParamvalues,
      fCn: temp,
      fCnnLayerNum: hyperParamvalues.fCnnLayerNum - 1,
    }))
  }

  const addFCnn = () => {
    if (hyperParamvalues.fCnnLayerNum < hyperParamvalues.maxLayerNum) {
      let temp = [...hyperParamvalues.fCn];
      temp.push(
        <div key={hyperParamvalues.fCnnLayerNum}>
          <div style={{ paddingTop: '15px' }} >
            <Slider
              name='num_fcn[]'
              style={{ color: '#1165f1', width: '250px' }}
              defaultValue={256}
              aria-labelledby="discrete-slider-small-steps"
              min={1} max={512} step={1}
              valueLabelDisplay="on"
            />
            <img src={dnn_layer} style={{ width: '200px', marginLeft: '100px' }}></img>
          </div>
          <div style={{ textAlign: 'center' }}>
            <img src={arrowInput} style={{ height: '32px', marginRight: '50px' }}></img>
            <img src={arrowInput} style={{ height: '32px' }}></img>
          </div>
        </div>
      )
      setHyperParamvalues((hyperParamvalues) => ({
        ...hyperParamvalues,
        fCn: temp,
        fCnnLayerNum: hyperParamvalues.fCnnLayerNum + 1
      }))
    }
  }

  return (
    <div style={{ width: '100%', paddingTop: '30px' }}>
      <label>Create a custom Hyperparameters</label>
      <Grid container >
        {/* top layer */}
        <Grid item xs={12} style={{ backgroundColor: '#fad7da' }}>
          <div style={{ textAlign: 'center' }}>
            <img src={csvImg} style={{ width: '100px', marginLeft: '15px', marginTop: '' }}></img>
          </div>
          <div style={{ textAlign: 'center' }}>
            <img src={arrowInput} style={{ height: '32px', marginRight: '50px' }}></img>
            <img src={arrowInput} style={{ height: '32px' }}></img>
          </div>
        </Grid>
        {/* Second layer left */}
        <Grid item name='leftCNN' xs={6} style={{ backgroundColor: '#f2f2bd', padding: '5px' }}>
          <div style={{ textAlign: 'center' }}>
            <Typography variant="subtitle1"> Tower 1 (CNN)</Typography>
          </div>
          <div>
            <span>Add/Delete layer (min:1, max:3)</span>
            <Button type='button' size='small'
              style={{
                marginLeft: '40px',
                backgroundColor: '#2A3F54',
                color: 'white'
              }}
              onClick={addTower1}
            >Add Layer</Button>
            <Button type='button' size='small'
              style={{
                marginLeft: '5px',
                backgroundColor: '#2A3F54',
                color: 'white'
              }}
              onClick={delTower1}
            >Del Layer</Button>
          </div>
          <div style={{ paddingTop: '50px' }}>
            <Slider
              name='tower1_filters[]'
              style={{ color: '#1165f1', width: '100px' }}
              defaultValue={10}
              aria-labelledby="discrete-slider-small-steps"
              min={3} max={12} step={1}
              valueLabelDisplay="on"
            />
            <Slider
              name='tower1_kernel_size[]'
              style={{ color: 'rgba(239, 28, 65, 0.97)', width: '100px', marginLeft: '30px' }}
              defaultValue={4}
              aria-labelledby="discrete-slider-small-steps"
              min={4} max={30} step={1}
              valueLabelDisplay="on"
            />
            <img src={cnn_yellow} style={{ width: '150px', marginLeft: '50px', marginTop: '-20px' }}></img>
          </div>
          {
            Object.entries(hyperParamvalues.tower1).map(element => {
              return element;
            })
          }
        </Grid>
        {/* second layer right */}
        <Grid item xs={6} style={{ backgroundColor: '#cffad1', padding: '5px' }}>
          <div style={{ textAlign: 'center' }}>
            <Typography variant="subtitle1"> Tower 2 (CNN)</Typography>
          </div>
          <div>
            <span>Add/Delete layer (min:1, max:3)</span>
            <Button type='button' size='small'
              style={{
                marginLeft: '40px',
                backgroundColor: '#2A3F54',
                color: 'white'
              }}
              onClick={addTower2}
            >Add Layer</Button>
            <Button type='button' size='small'
              style={{
                marginLeft: '5px',
                backgroundColor: '#2A3F54',
                color: 'white'
              }}
              onClick={delTower2}
            >Del Layer</Button>
          </div>
          <div style={{ paddingTop: '50px' }}>
            <img src={cnn_green} style={{ width: '150px', marginTop: '-20px' }}></img>
            <Slider
              name='tower2_filters[]'
              style={{ color: '#1165f1', width: '100px', marginLeft: '45px' }}
              defaultValue={10}
              aria-labelledby="discrete-slider-small-steps"
              min={3} max={12} step={1}
              valueLabelDisplay="on"
            />
            <Slider
              name='tower2_kernel_size[]'
              style={{ color: 'rgba(239, 28, 65, 0.97)', width: '100px', marginLeft: '30px' }}
              defaultValue={4}
              //   getAriaValueText={valuetext}
              aria-labelledby="discrete-slider-small-steps"
              min={4} max={30} step={1}
              valueLabelDisplay="on"
            />
          </div>
          {
            Object.entries(hyperParamvalues.tower2).map(element => {
              return element;
            })
          }
        </Grid>
        {/* last layer */}
        <Grid item xs={12} style={{ backgroundColor: '#ded9fc', padding: '5px' }}>
          <div style={{ textAlign: 'center' }}>
            <img src={arrowInput} style={{ height: '32px', marginRight: '50px' }}></img>
            <img src={arrowInput} style={{ height: '32px' }}></img>
          </div>
          <div style={{ paddingBottom: '20px' }}>
            <span style={{ marginLeft: '100px' }}>Size</span>
            <span variant="subtitle1" style={{ marginLeft: '200px' }}>Fully connected neural network</span>
            <Button type='button' size='small'
              style={{
                marginLeft: '110px',
                backgroundColor: '#2A3F54',
                color: 'white'
              }}
              onClick={addFCnn}
            >Add Layer</Button>
            <Button type='button' size='small'
              style={{
                marginLeft: '10px',
                backgroundColor: '#2A3F54',
                color: 'white'
              }}
              onClick={delFCn}
            >Del Layer</Button>
          </div>
          {
            Object.entries(hyperParamvalues.fCn).map(element => {
              return element;
            })
          }
          <div>
            <Slider
              name='num_fcn[]'
              style={{ color: '#1165f1', width: '250px' }}
              defaultValue={1}
              aria-labelledby="discrete-slider-small-steps"
              min={1} max={512} step={1}
              valueLabelDisplay="on"
            />
            <img src={dnn_output} style={{ width: '22px', marginLeft: '180px' }}></img>
          </div>
        </Grid>
      </Grid>
      {CreateModelLegendsDiv}
    </div>
  )
}
