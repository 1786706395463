import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Backdrop, Button, Card, CardActions, CardContent, CircularProgress, Divider, FormControl, FormLabel, MenuItem, Select, Slider, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import DatagridDemo from '../util/DatagridTable';
import { formHeadingCss } from '../util/formHeadingCss';
import { formSubmitButtonCss } from '../util/formSubmitButtonCss';
import { StudyresultsLegendsDiv } from '../util/LegendsDiv';
import { bioMarkersTableColumns, resultsTableColumns } from '../util/tableColumnsDetails';
import UpsetPlotUtil from '../util/UpsetPlotUtil';
import ValueTable from './ValueTable';

export default function StudyResults() {

    const [database, setDatabase] = useState("");
    const [loading, setLoading] = useState(false);
    const [tumorName, setTumorName] = useState("");
    const [databaseList, setDatabaseList] = useState([]);
    const [tumorList, setTumorList] = useState([]);
    const [numMarkers, setNumMarkers] = useState(100);
    const [saliencyResultsforSingle, setSaliencyResultsforSingle] = useState([]);
    const [saliencyResultsforDual, setSaliencyResultsforDual] = useState([]);
    const [saliencyResultsforMulti, setSaliencyResultsforMulti] = useState([]);
    const [singleStudyGseaDetails, setSingleStudyGseaDetails] = useState([]);

    const [selectedCells, setSelectedCells] = useState([]);
    const [compareCells, setCompareCells] = useState([]);
    const [compareResults, setCompareResults] = useState([]);
    const [selection, setSelection] = useState(null);
    const [loadGsea, setLoadGsea] = useState(false);
    const [pageSize, setPageSize] = useState(5);

    const marks = [
        {
            value: 100,
            label: '100',
        },
        {
            value: 150,
            label: '150',
        },
        {
            value: 200,
            label: '200',
        },
        {
            value: 250,
            label: '250',
        },
        {
            value: 300,
            label: '300',
        },
        {
            value: 350,
            label: '350',
        },
        {
            value: 400,
            label: '400',
        },
        {
            value: 450,
            label: '450',
        },
        {
            value: 500,
            label: '500',
        }
    ];

    let navigate = useNavigate();

    useEffect(() => {
        fetchDatabaseList();
    }, [])

    useEffect(() => {
        fetchDiseaseNamesList();
    }, [database])

    useEffect(() => {
        setSaliencyResultsforSingle([]);
        setSaliencyResultsforDual([]);
        setSaliencyResultsforMulti([]);
        clearSelectionResults();
    }, [database, numMarkers, tumorName])


    const submitProject = () => {
        clearSelectionResults();
        setLoading(true);
        fetchSaliencyResultsForSingleOmics();
        fetchSaliencyResultsForDualOmics();
        fetchSaliencyResultsForMultiOmics();

    }

    const fetchSaliencyResultsForSingleOmics = () => {
        fetch(`/api/study_results/retrieve_saliency_results_summary/?study_database=${database}&tumor_name=${tumorName}&num_markers=${numMarkers}&num_omics=1`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response =>
                response.json()
            )
            .then(data => {
                setSaliencyResultsforSingle(data);
                return data;
            });

    }
    const fetchSaliencyResultsForDualOmics = () => {
        fetch(`/api/study_results/retrieve_saliency_results_summary/?study_database=${database}&tumor_name=${tumorName}&num_markers=${numMarkers}&num_omics=2`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response =>
                response.json()
            )
            .then(data => {
                setSaliencyResultsforDual(data);
                return data;
            });

    }

    const fetchSaliencyResultsForMultiOmics = () => {
        fetch(`/api/study_results/retrieve_saliency_results_summary/?study_database=${database}&tumor_name=${tumorName}&num_markers=${numMarkers}&num_omics=3`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response =>
                response.json()
            )
            .then(data => {
                setSaliencyResultsforMulti(data);
                setLoading(false);
                return data;
            });

    }

    const fetchDatabaseList = () => {

        fetch('/api/information/fetch_all_study_cases_database', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                setDatabaseList(data.message);
                return data;
            })
    }

    const fetchDiseaseNamesList = () => {

        fetch(`/api/information/fetch_all_study_cases_disease_names?study_database=${database}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == "SUCCESS") {
                    setTumorList(data.message);
                }
                return data;
            })
    }

    const fetchCompareBioMarkersList = () => {

        let raw = JSON.stringify({
            "tumor_name": tumorName,
            "num_markers": numMarkers,
            "selected": compareCells
        });

        fetch('/api/study_results/retrieve_upset_plot_biomarkers_data/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: raw
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                if (data.status == "SUCCESS") {
                    console.log(data.message)
                    setCompareResults(data.message);
                    setLoading(false);
                }
                return data;
            })
    }

    // const boxplotdata = () => {

    //     let raw = JSON.stringify({
    //         "tumor_name": 'COAD',
    //         "num_markers": 100,
    //         "selected": [
    //             {
    //                 "model_type_key": "CNN",
    //                 "dataset_type_keys": "MIRNA_EXPRESSION|PROTEIN_EXPRESSION"
    //             }
    //         ]
    //     });

    //     fetch('/api/study_results/retrieve_upset_plot_biomarkers_data/', {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: raw
    //     })
    //         .then(res => res.json())
    //         .then(data => {
    //             console.log(data);
    //             if (data.status == "SUCCESS") {
    //                 console.log(data.message)
    //                 setCompareResults(data.message);
    //                 setLoading(false);
    //             }
    //             return data;
    //         })
    // }

    const updateSignificanceValue = (event, newValue) => {
        setNumMarkers(newValue);
    }


    const databaseUpdate = (e) => {
        setDatabase(e.target.value)
        setTumorName("");
    }

    const handleCellClick = (params) => {
        const cellId = params.field + '_' + params.id + '_' + params.row.dataset;
        if (
            selectedCells.includes(cellId) ||
            params.field === 'dataset'
        ) {
            setSelectedCells(selectedCells.filter((id) => id !== cellId));

            setCompareCells(
                compareCells.filter((a) =>
                    (a.dataset_type_keys !== params.row.dataset || a.model_type_key != params.field))
            );

        } else if (selectedCells.length < 4) {
            setSelectedCells([...selectedCells, cellId]);

            setCompareCells([
                ...compareCells,
                { model_type_key: params.field, dataset_type_keys: params.row.dataset }
            ])
        }

    };

    const cellClassName = (params) => {
        const cellId = params.field + '_' + params.id + '_' + params.row.dataset;
        return selectedCells.includes(cellId) ? 'selected-cell' : '';
    };

    const submitCompare = () => {
        setCompareResults([]);
        setSingleStudyGseaDetails([]);
        setLoadGsea(true);
        setLoading(true);
        fetchCompareBioMarkersList();
        // boxplotdata();

        setTimeout(() => {
            getGseaResults();
        }, 5000);



    }

    const clearSelectionResults = () => {
        setSelectedCells([]);
        setCompareCells([]);
        setCompareResults([]);
        setSingleStudyGseaDetails([]);
        setSelection(null);
        setLoadGsea(false);
    }

    const getGseaResults = () => {
        {
            compareCells.map((cell) => {
                fetch(`/api/study_results/retrieve_single_study_result_gsea/?tumor_name=${tumorName}&num_markers=${numMarkers}&model_type_key=${cell.model_type_key}&dataset_type_keys=${cell.dataset_type_keys}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                })
                    .then(res => res.json())
                    .then(data => {
                        setSingleStudyGseaDetails([...singleStudyGseaDetails, data]);
                        if (singleStudyGseaDetails.length == compareCells.length) {
                            setLoadGsea(false);
                        }
                    });
            }
            );
        }

    }

    return (
        <>
            <div>
                <h3 style={{
                    textAlign: "justify"
                }}> Study Results</h3>
                <div style={{ textAlign: "justify" }}>
                    <p>Instructions:</p>
                    <ul>
                        <li>The results are the significance markers for phenotype (long-term survival and non-long-term survival).</li>
                        <li>A tutorial video is <a href='https://g2pdeep.org/workshop'>here</a>.</li>
                    </ul>
                </div>
                <Typography
                    style={formHeadingCss}>
                    Study Results
                </Typography>
                <Card style={{
                    width: '50%',
                    alignContent: 'left',
                    textAlign: 'left',
                    margin: 'auto',
                    marginBottom: '2%',
                    boxShadow: ' 0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)',
                    fontWeight: '500'
                }}>
                    <CardContent>
                        <FormControl >
                            <FormLabel required>Choose Database</FormLabel>
                            <Select
                                value={database}
                                onChange={e => { databaseUpdate(e) }}
                                required
                            >
                                {Object.entries(databaseList).map(([key, value]) =>
                                    <MenuItem key={key} value={value}> {value}</MenuItem >
                                )}
                            </Select>
                        </FormControl>
                        <br></br>
                        <br></br>

                        <FormControl >
                            <FormLabel required> Disease Name</FormLabel>
                            <Select
                                name="data_type"
                                value={tumorName}
                                onChange={e => { setTumorName(e.target.value) }}
                                defaultValue='COAD'
                                required>

                                {Object.entries(tumorList).map(([key, value]) =>
                                    <MenuItem key={key} value={value.key}> {value.value} {"(" + value.key + ")"}</MenuItem >
                                )}
                            </Select>
                        </FormControl>

                        <br></br><br></br>
                        <div>
                            <FormLabel> Please select the Number of top significant features</FormLabel>
                            <Slider
                                name='percentage'
                                style={{ color: '#1165f1', width: '100%', outerHeight: '20px' }}
                                defaultValue={100}
                                aria-labelledby="discrete-slider-small-steps"
                                min={100} max={500} step={50}
                                valueLabelDisplay="on"
                                marks={marks}
                                onChange={updateSignificanceValue}

                            />
                        </div>
                    </CardContent>

                    <CardActions>
                        <Button style={formSubmitButtonCss} onClick={submitProject} size="large" disabled={tumorName == ""}>Sumbit</Button>
                    </CardActions>

                </Card >
            </div>

            <div>
                {loading ? (<div><Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                    <Typography> Dont refresh while fetching results </Typography>
                </Backdrop></div>) : ''}
            </div>


            <div >
                {(saliencyResultsforSingle == "" | saliencyResultsforDual == "" | saliencyResultsforMulti == "") ? ("") : (<div>
                    <Card style={{
                        width: '90%',
                        alignContent: 'center',
                        textAlign: 'center',
                        margin: '2%',
                        justifyContent: 'center'
                    }}>

                        <CardContent>
                            <Typography style={{
                                textAlign: 'center',
                                paddingTop: '1%',
                                fontSize: 'larger',
                                fontWeight: 'bold'
                            }} >
                                Single Omics Table <span style={{ fontSize: 'smaller', fontWeight: 'normal' }}>[No.of samples (AUC)]</span>
                            </Typography>
                            <DatagridDemo data={saliencyResultsforSingle.ATTRIBUTES.TABLE_RECORDS} columns={resultsTableColumns} handleCellClick={handleCellClick} cellClassName={cellClassName} />
                            {StudyresultsLegendsDiv}

                            <Typography style={{
                                textAlign: 'center',
                                paddingTop: '3%',
                                fontSize: 'larger',
                                fontWeight: 'bold'
                            }} > Dual Omics Table <span style={{ fontSize: 'smaller', fontWeight: 'normal' }}>[No.of samples (AUC)]</span></Typography>
                            <DatagridDemo data={saliencyResultsforDual.ATTRIBUTES.TABLE_RECORDS} columns={resultsTableColumns} handleCellClick={handleCellClick} cellClassName={cellClassName} />
                            {StudyresultsLegendsDiv}

                            <Typography style={{
                                textAlign: 'center',
                                paddingTop: '3%',
                                fontSize: 'larger',
                                fontWeight: 'bold'
                            }} > Multi Omics Table <span style={{ fontSize: 'smaller', fontWeight: 'normal' }}>[No.of samples (AUC)]</span></Typography>
                            <DatagridDemo data={saliencyResultsforMulti.ATTRIBUTES.TABLE_RECORDS} columns={resultsTableColumns} handleCellClick={handleCellClick} cellClassName={cellClassName} />

                            {StudyresultsLegendsDiv}
                        </CardContent>

                    </Card>
                    <CardActions >
                        <Button style={formSubmitButtonCss} onClick={clearSelectionResults} size="medium" disabled={selectedCells.length < 1} >Clear Selection</Button>

                        <Button style={formSubmitButtonCss} onClick={submitCompare} size="medium" disabled={selectedCells.length < 1}>Compare</Button>
                    </CardActions>

                </div>)}
                <div>
                    {compareResults == "" ? ("") : (<div>
                        <Card style={{
                            width: '90%',
                            alignContent: 'center',
                            textAlign: 'center',
                            margin: '2%',
                            justifyContent: 'center'
                        }}>
                            <CardContent>
                                <Typography style={{
                                    textAlign: 'center',
                                    paddingTop: '2%',
                                    fontSize: 'larger'
                                }} > Upset Plot Comparison</Typography>

                                <UpsetPlotUtil elems={compareResults} selection={selection} setSelection={setSelection} />
                            </CardContent>
                        </Card>


                    </div>)}
                </div>
                <br></br>
                {(selection == null) ? ("") : (<div>

                    <Card style={{
                        width: '90%',
                        alignContent: 'center',
                        textAlign: 'center',
                        margin: '2%',
                        justifyContent: 'center'
                    }}>
                        <CardContent>
                            <Typography style={{ textAlign: 'left', fontSize: 'large' }}>Total Number of Biomarkers are {selection.cardinality}</Typography>

                            <div style={{ height: 400, width: '100%', paddingTop: '1%' }}>
                                <DataGrid
                                    rows={selection.elems}
                                    columns={bioMarkersTableColumns}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    hideFooterSelectedRowCount
                                    rowsPerPageOptions={[5, 10, 15]}
                                    getRowId={(row) => row.name}
                                    pagination
                                    autoPageSize
                                />
                            </div>
                        </CardContent>
                    </Card>

                </div>)}
                <br></br>

                <div style={{ textAlign: "center" }}>
                    {(loadGsea === true && singleStudyGseaDetails === "") ? (<>
                        <CircularProgress color="inherit" />
                        <p>Please wait while loading...!!</p>
                    </>) : (<div>

                        {singleStudyGseaDetails.map((singleStudyGseaDetail) => {
                            return <div>
                                <Accordion style={{ textAlign: 'left', maxHeight: '720px', overflow: 'auto', margin: '2%' }}>
                                    <AccordionSummary
                                        style={{ width: '100%', backgroundColor: 'rgb(198 216 237)', padding: '0px 15px 0px 15px' }}
                                        expandIcon={<ExpandMoreIcon style={{ float: 'right' }} />}
                                    >
                                        <Typography style={{
                                            fontSize: 'large',
                                            fontWeight: 'bold'
                                        }}>{singleStudyGseaDetail.GROUP_NAME}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {(singleStudyGseaDetail.ATTRIBUTES).map((val, index) => (<div key={index}>
                                            <h3 style={{
                                                textDecoration: 'underline'
                                            }}> {val.ATTRIBUTE_NAME}</h3>

                                            <div>
                                                {val.IMAGE_BASE64 == null ? (<p style={{ color: 'red' }}>Image not available yet</p>) : (<img style={{
                                                    width: '800',
                                                    height: '600px',
                                                    maxWidth: '100%'
                                                }}
                                                    src={`data:image/jpeg;base64,${val.IMAGE_BASE64}`} />)}
                                                <br></br><br></br>
                                            </div>

                                            {(val.AVAILABLE) ? (
                                                <div >
                                                    <ValueTable data={val.TABLE_RECORDS} />
                                                    <br></br><br></br>
                                                </div>

                                            ) : (<>
                                                <p> {val.NOTICE}</p> </>
                                            )}
                                            <Divider />
                                        </ div>)
                                        )}
                                    </AccordionDetails>
                                </Accordion>

                            </div>
                        })}

                    </div>)}

                </div>
            </div>
        </>
    )
}

