import { Card, CardActions, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from "react-router-dom";
import PrivateTestProjectTable from './PrivateTestProjectTable';

export default function Projects({ setToken }) {

    const token = sessionStorage.getItem('token');

    let navigate = useNavigate();

    const loginPageRedirect = () => {
        navigate("/login");
    }

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={6} >
                    <div style={{ textAlign: "justify" }}>
                        <h3> Summary of Projects</h3>
                        <p>Instruction:</p>
                        <ul>
                            <li>The project can be created and monitored after <Link to="/login" > login</Link>.</li>
                        </ul>
                    </div>
                </Grid>
                {token != null ?
                    <Grid item xs={6} >
                        <Link to='/create_project'>
                            <button type='button' style={{
                                margin: '10%',
                                padding: '5px',
                                backgroundColor: '#2A3F54',
                                color: 'white',
                                float: 'right',
                                borderRadius: '5px'
                            }}>PROJECT CREATION</button>
                        </Link>

                    </Grid>
                    : ''}
            </Grid>
            <div>
                {token != null ? (
                    <PrivateTestProjectTable setToken={setToken} />
                ) : (
                    <Card style={{ border: '1px solid' }}>
                        <CardHeader title={<Typography variant={"h6"} style={{
                            float: 'left'
                        }}>Projects Summary</Typography>} />
                        <Divider></Divider>
                        <CardContent> Please login to see your project information</CardContent>
                        <CardActions>
                            <Button style={{
                                margin: 'auto',
                                backgroundColor: '#2A3F54',
                                color: 'white'
                            }} onClick={loginPageRedirect} >Login</Button>
                        </CardActions>
                    </Card>)}
            </div>

        </>
    )
}
