import React from 'react';

export const datasetLegendsDiv = <div style={{
    display: 'flex',
    marginTop: '10px'
}}>
    <div style={{
        width: '20px',
        height: '20px',
        backgroundColor: '#FFEC9E',
        borderRight: '5px',
        margin: '3px 10px 0px 20px'
    }}></div> <p> miRNA</p>
    <div style={{
        width: '20px',
        height: '20px',
        backgroundColor: '#FFC69D',
        margin: '3px 10px 0px 20px'
    }}></div> <p> Gene</p>
    <div style={{
        width: '20px',
        height: '20px',
        backgroundColor: '#9EFFC8',
        margin: '3px 10px 0px 20px'
    }}></div> <p>Protein</p>
    <div style={{
        width: '20px',
        height: '20px',
        backgroundColor: '#DDE5FE',
        margin: '3px 10px 0px 20px'
    }}></div> <p>CNV</p>
    <div style={{
        width: '20px',
        height: '20px',
        backgroundColor: '#C6EB98',
        margin: '3px 10px 0px 20px'
    }}></div> <p>DNA methylation</p>
    <div style={{
        width: '20px',
        height: '20px',
        backgroundColor: '#BBE4EA',
        margin: '3px 10px 0px 20px'
    }}></div> <p>Zygosity</p>
    <div style={{
        width: '20px',
        height: '20px',
        backgroundColor: '#fce6e6',
        margin: '3px 10px 0px 20px'
    }}></div> <p>SNP</p>
</div>;

export const ModelLegendsDiv = <div style={{
    display: 'flex',
    marginTop: '10px'
}}>
    <div style={{
        width: '20px',
        height: '20px',
        backgroundColor: '#FFEC9E',
        borderRight: '5px',
        margin: '3px 10px 0px 20px'
    }}></div> <p> Default Hyperparameters</p>
    <div style={{
        width: '20px',
        height: '20px',
        backgroundColor: '#FFC69D',
        margin: '3px 10px 0px 20px'
    }}></div> <p> Customize Hyperparameters</p>
    <div style={{
        width: '20px',
        height: '20px',
        backgroundColor: '#9EFFC8',
        margin: '3px 10px 0px 20px'
    }}></div> <p>Automate Hyperparameter Tuning</p>
</div>;

export const CreateModelLegendsDiv = <div style={{
    display: 'flex',
    marginTop: '10px'
}}>
    <div style={{
        width: '20px',
        height: '20px',
        backgroundColor: 'blue',
        borderRight: '5px',
        margin: '3px 10px 0px 20px'
    }}></div> <p> Filters size</p>
    <div style={{
        width: '20px',
        height: '20px',
        backgroundColor: 'red',
        margin: '3px 10px 0px 20px'
    }}></div> <p>Kernel Size</p>
</div>;

export const StudyresultsLegendsDiv = <div>
    {/* <div style={{
        display: 'flex',
        marginTop: '10px'
    }}>
        <div style={{
            width: '20px',
            height: '20px',
            backgroundColor: '#FFEC9E',
            borderRight: '5px',
            margin: '3px 10px 0px 20px'
        }}></div> <p> Single Omics</p>
        <div style={{
            width: '20px',
            height: '20px',
            backgroundColor: '#FFC69D',
            margin: '3px 10px 0px 20px'
        }}></div> <p> Two Omics</p>
        <div style={{
            width: '20px',
            height: '20px',
            backgroundColor: '#9EFFC8',
            margin: '3px 10px 0px 20px'
        }}></div> <p>Three Omics</p>
    </div> */}

    <div style={{
        display: 'flex',
        marginTop: '5px'
    }}>
        <div style={{
            display: 'inline-block',
            width: "25px",
            height: "25px",
            border: '1px solid black',
            textAlign: 'center',
            verticalAlign: 'middle',
            margin: '3px 10px 0px 20px',
        }}>
            <p style={{
                margin: 0,
                fontSize: '15px',
                fontWeight: 'bolder',
                fontStyle: 'italic'
            }}>C</p>
        </div> <p> CNV</p>
        <div style={{
            display: 'inline-block',
            width: "25px",
            height: "25px",
            border: '1px solid black',
            textAlign: 'center',
            verticalAlign: 'middle',
            margin: '3px 10px 0px 20px'
        }}>
            <p style={{
                margin: 0,
                fontSize: '15px',
                fontWeight: 'bolder',
                fontStyle: 'italic'
            }}>D</p>
        </div> <p>DNA Methylation</p>
        <div style={{
            display: 'inline-block',
            width: "25px",
            height: "25px",
            border: '1px solid black',
            textAlign: 'center',
            verticalAlign: 'middle',
            margin: '3px 10px 0px 20px'
        }}>
            <p style={{
                margin: 0,
                fontSize: '15px',
                fontWeight: 'bolder',
                fontStyle: 'italic'
            }}>G</p>
        </div> <p> Gene Expression</p>
        <div style={{
            display: 'inline-block',
            width: "25px",
            height: "25px",
            border: '1px solid black',
            textAlign: 'center',
            verticalAlign: 'middle',
            margin: '3px 10px 0px 20px'
        }}>
            <p style={{
                margin: 0,
                fontSize: '15px',
                fontWeight: 'bolder',
                fontStyle: 'italic'
            }}>S</p>
        </div> <p>SNP</p>
        <div style={{
            display: 'inline-block',
            width: "25px",
            height: "25px",
            border: '1px solid black',
            textAlign: 'center',
            verticalAlign: 'middle',
            margin: '3px 10px 0px 20px'
        }}>
            <p style={{
                margin: 0,
                fontSize: '15px',
                fontWeight: 'bolder',
                fontStyle: 'italic'
            }}>P</p>
        </div> <p> Protein Expression</p>
        <div style={{
            display: 'inline-block',
            width: "25px",
            height: "25px",
            border: '1px solid black',
            textAlign: 'center',
            verticalAlign: 'middle',
            margin: '3px 10px 0px 20px'
        }}>
            <p style={{
                margin: 0,
                fontSize: '15px',
                fontWeight: 'bolder',
                fontStyle: 'italic'
            }}>M</p>
        </div> <p> miRNA Expression</p>

        <div style={{
            display: 'inline-block',
            width: "25px",
            height: "25px",
            border: '1px solid black',
            textAlign: 'center',
            verticalAlign: 'middle',
            margin: '3px 10px 0px 20px'
        }}>
            <p style={{
                margin: 0,
                fontSize: '15px',
                fontWeight: 'bolder',
                fontStyle: 'italic'
            }}>Z</p>
        </div> <p> Zygosity</p>
    </div>
</div >;

export const ModelHparamLegendsDiv =
    <div style={{
        display: 'flex',
        marginTop: '5px'
    }}>
        <div style={{
            display: 'inline-block',
            width: "25px",
            height: "25px",
            border: '1px solid black',
            textAlign: 'center',
            verticalAlign: 'middle',
            margin: '3px 10px 0px 20px',
        }}>
            <p style={{
                margin: 0,
                fontSize: '14px',
                fontWeight: 'bolder',
                fontStyle: 'italic'
            }}>DH</p>
        </div> <p> Default Hyperparameters</p>
        <div style={{
            display: 'inline-block',
            width: "25px",
            height: "25px",
            border: '1px solid black',
            textAlign: 'center',
            verticalAlign: 'middle',
            margin: '3px 10px 0px 20px'
        }}>
            <p style={{
                margin: 0,
                fontSize: '14px',
                fontWeight: 'bolder',
                fontStyle: 'italic'
            }}>CH</p>
        </div> <p>Custom Hyperparameters</p>
        <div style={{
            display: 'inline-block',
            width: "25px",
            height: "25px",
            border: '1px solid black',
            textAlign: 'center',
            verticalAlign: 'middle',
            margin: '3px 10px 0px 20px'
        }}>
            <p style={{
                margin: 0,
                fontSize: '14px',
                fontWeight: 'bolder',
                fontStyle: 'italic'
            }}>AH</p>
        </div> <p>Automated Hyperparameter Tuning</p>
    </div >;