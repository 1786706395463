import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { useLocation, useNavigate } from "react-router";
import { logoutFakeUser } from '../Auth/logoutFakeUser';
import { checkAuth } from '../Auth/checkAuth';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';
import { Backdrop, CircularProgress } from '@mui/material';

export default function ProjectInformation({ setToken }) {

    let data = useLocation();
    const navigate = useNavigate();

    const [base64Data, setBase64Data] = useState("");
    const [projectDetailsFigures, setProjectDetailsFigures] = useState([]);

    const token = sessionStorage.getItem("token");

    const checkAuthentication = checkAuth({ token, setToken, navigate })

    useEffect(() => {

        checkAuthentication()
            .then(result => {
                getImageBase64();
                getProjectDetailsFigures();
            });

    }, [])

    const proj_id = data.state.row.project_info_id;

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const [dataLoading, setDataLoading] = useState(false);
  
    const handleDeleteIconClick = () => {
      setShowConfirmationDialog(true);
    };

    const handleConfirmDelete = () => {

        setDataLoading(true);
       
        let myHeaders = new Headers();
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
       
        setTimeout(() => {

            var raw = JSON.stringify({
              "project_info_id": proj_id
          });
       
            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow',
          };
               
          fetch('/api/operation/delete_project/', requestOptions)
              .then(res => res.json())
              .then(result => {
                {
                    setDataLoading(false);
                }
            })
              .catch(reqErr => console.error(reqErr));
    
              navigate('/projects');
              window.location.reload();
      }, 5000);
        
        setShowConfirmationDialog(false);
      };
    
      const handleCancelDelete = () => {
        // Close the confirmation dialog
        setShowConfirmationDialog(false);
      };

    const getImageBase64 = () => {

        fetch(`/api/modeling/retrieve_project_ml_model_image_base64/?project_info_id=${data.state.row.project_info_id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` }
        })
            .then(res => res.json())
            .then(data => {
                setBase64Data(data.message.image_base64);
                return data;
            })
    }


    const getProjectDetailsFigures = () => {

        fetch(`/api/operation/fetch_project_details_figures/?project_info_id=${data.state.row.project_info_id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` }
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === "SUCCESS") {
                    setProjectDetailsFigures(data.message);
                }
                return data;
            })
    }


    return (
        <div style={{ textAlign: "justify" }}>

            <p>
                This page will provide you the complete project information
            </p>
            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        backgroundColor: "#dae7f5"
                    }}
                >
                    <Typography >Project details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Value</th>
                                </tr>

                            </thead>
                            <tbody>
                                {
                                    <>
                                        <tr className="active-row">
                                            <td> Project name</td>
                                            <td> {data.state.row.project_name}</td>
                                        </tr>
                                        <tr className="active-row">
                                            <td> Description</td>
                                            <td> {data.state.row.description}</td>
                                        </tr>
                                        <tr className="active-row">
                                            <td> Created at</td>
                                            <td> {data.state.row.created_at}</td>
                                        </tr>
                                        <tr className="active-row">
                                            <td> Updated at</td>
                                            <td> {data.state.row.updated_at}</td>
                                        </tr>

                                    </>
                                }

                            </tbody>
                        </table>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        backgroundColor: '#f2f5fa'
                    }}
                >
                    <Typography> Model details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody >
                                {
                                    <>
                                        <tr className="active-row">
                                            <td> Model name</td>
                                            <td> {data.state.row.ml_model_info.model_name}</td>
                                        </tr>
                                        <tr className="active-row">
                                            <td> Supported datasets</td>
                                            <td> {data.state.row.ml_model_info.dataset_type_name_vlsv}</td>
                                        </tr>
                                        <tr className="active-row">
                                            <td> Label Type name</td>
                                            <td> {data.state.row.ml_model_info.label_type_name}</td>
                                        </tr>
                                        <tr className="active-row">
                                            <td> Ml Model type</td>
                                            <td> {data.state.row.ml_model_info.ml_model_type}</td>
                                        </tr>
                                        <tr className="active-row">
                                            <td> Description</td>
                                            <td> {data.state.row.ml_model_info.description}</td>
                                        </tr>
                                        <tr className="active-row">
                                            <td> Created at</td>
                                            <td> {data.state.row.ml_model_info.created_at}</td>
                                        </tr>
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        backgroundColor: "#dae7f5"
                    }}
                >
                    <Typography> Model diagram</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        {base64Data == null ? (<p style={{ color: 'red' }}>Image Not available yet</p>) : (<img style={{
                            width: '800',
                            height: '600px',
                            maxWidth: '100%'
                        }}
                            src={`data:image/jpeg;base64,${base64Data}`} />)}
                    </div>
                </AccordionDetails>
            </Accordion>


            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        backgroundColor: '#f2f5fa'
                    }}>
                    <Typography>All dataset Information</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <div>
                        <table className="styled-table" style={{
                            // display: 'block',
                            overflowX: 'auto',
                            whiteSpace: 'nowrap'
                        }}>
                            <thead>
                                <tr>
                                    <th>Dataset Name</th>
                                    <th>Dataset Type</th>
                                    <th>#Samples</th>
                                    <th>#Features</th>
                                    <th>Training ratio</th>
                                    <th>Validation ratio</th>
                                    <th>Description</th>
                                </tr>
                            </thead>

                            <tbody>
                                {Object.entries(data.state.row.dataset_info_list).map(([key, value]) => {
                                    return (
                                        <>
                                            <tr className="active-row">
                                                <td> {value.dataset_name}</td>
                                                <td> {value.dataset_type_key}</td>
                                                <td> {value.num_samples}</td>
                                                <td> {value.num_features}</td>
                                                <td> {value.ratio_training_dataset}</td>
                                                <td> {value.ratio_validation_dataset}</td>
                                                <td> {value.description}</td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* <div>
                        <table className="styled-table" style={{
                            display: 'block',
                            overflowX: 'auto',
                            whiteSpace: 'nowrap'
                        }}>
                            <thead>
                                <tr>
                                    {Object.entries(data.state.row.dataset_info_list[0]).map(([key, value]) => {
                                        return (
                                            <th >{key}</th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(data.state.row.dataset_info_list).map(([key, value]) => {
                                    return (
                                        <>
                                            <tr key={key} className="active-row">
                                                {
                                                    Object.entries(value).map(([key1, value1]) => {
                                                        return (
                                                            <td> {value1}</td>
                                                        );
                                                    })
                                                }
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div> */}
                </AccordionDetails>
            </Accordion>

            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        backgroundColor: '#dae7f5'
                    }}>
                    <Typography>Diagrams</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        {projectDetailsFigures === "" ? (<> <p>Fail to load images</p></>) : (
                            Object.entries(projectDetailsFigures).map(([key, value]) => (
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{
                                            backgroundColor: '#dae7f5'
                                        }}>
                                        <Typography>{value.GROUP_NAME}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {value.ATTRIBUTES.map((fig) => {

                                            return (<>
                                                {(fig.AVAILABLE) ? (<>
                                                    <Plot
                                                        data={JSON.parse(fig.PLOTLY_FIGURE_JSON).data}
                                                        layout={JSON.parse(fig.PLOTLY_FIGURE_JSON).layout}
                                                    />
                                                </>) : (<><p style={{ fontWeight: 'bold' }}> {fig.ATTRIBUTE_NAME}</p>
                                                    <p> {fig.NOTICE}</p> </>
                                                )}
                                            </>)
                                        }
                                        )
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            )))

                        }
                    </div>
                </AccordionDetails>
            </Accordion>
            <Dialog open={showConfirmationDialog}>
                  <DialogTitle>Confirm Delete</DialogTitle>
                  <DialogContent>
                    <Typography>Are you sure you want to delete this Project?</Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleConfirmDelete} color="secondary">
                      Delete
                    </Button>
                    <Button onClick={handleCancelDelete} color="primary">
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
            <button id={proj_id} type='button' onClick={handleDeleteIconClick} style={{
                    backgroundColor: 'rgb(42, 63, 84)',
                    marginTop: '20px',
                    color: 'white',
                    borderRadius: '5px'
                }}>Delete Project</button>
                        <div>
                {dataLoading ? (<div><Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={dataLoading}
                >
                    <CircularProgress color="inherit" />
                    <Typography> Please donot refresh while deleting</Typography>
                </Backdrop></div>) : ''}
            </div>
        </div >
    )
}
