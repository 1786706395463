import { logoutFakeUser } from './logoutFakeUser';

export function checkAuth({token, setToken, navigate}) {
    return async () => {
        const response = await fetch('/api/auth/check_authentication/', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` }
        });

        if (!response.ok) {
            logoutFakeUser(setToken, navigate);
        } else {
            return response.json();
        }
    };
}
