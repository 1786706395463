import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'

export default function DefaultHyperParam({ model, inputdataType }) {

    const [base64Image, setBase64Image] = useState("");

    useEffect(() => {
        getImage();
    }, [])


    const getImage = () => {

        fetch(`/api/modeling/retrieve_default_ml_model_image_base64/?dataset_type_key_csv=${inputdataType.toString()}&model_type_key=${model}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                setBase64Image(data.message.image_base64);
                return data;
            })
    }

    return (
        <div >

            {base64Image == null ? (<p style={{ color: 'red' }}>Image Not available yet</p>) : (<img style={{
                width: '800',
                height: '600px',
                maxWidth: '100%'
            }}
                src={`data:image/jpeg;base64,${base64Image}`} />)}

        </div>
    )
}
