import { Link } from 'react-router-dom'
import './Footer.css'
import React from 'react';
import { useEffect, useState, useRef } from 'react';


export default function Footer() {

  const [Models, setModels] = useState([]);
  const [Datasets, setDatasets] = useState([]);
  const [Projects, setProjects] = useState([]);

  const didMount = useRef(true);

  useEffect(() => {
      if (didMount.current) {
        fetchAllModels();
        fetchAllDatasets();
        fetchAllProjects();
          didMount.current = false;
      }
  }, [])

  const fetchAllModels = () => {

    fetch('/api/analytics/get_model_count/', {
        method: 'GET'
    })
        .then(res => res.json())
        .then(data => {
            setModels(data.message.num_models);
            return data;
        })
  }

  const fetchAllDatasets = () => {

    fetch('/api/analytics/get_dataset_count/', {
        method: 'GET'
    })
        .then(res => res.json())
        .then(data => {
          setDatasets(data.message.num_datasets);
            return data;
        })
  }

  const fetchAllProjects = () => {

    fetch('/api/analytics/get_project_count/', {
        method: 'GET'
    })
        .then(res => res.json())
        .then(data => {
          setProjects(data.message.num_projects);
            return data;
        })
  }

  return (
    <div style={{ backgroundColor: '#e6f2ff', display: 'flex' }}>
      <div style={{ width: '75%', display: 'inline-block', textAlign: 'left', borderRight: '1px solid black', padding: '10px' }}>
        <h5 style={{ fontWeight: '400' }}>Publications</h5>
        <p style={{ fontSize: '14px' }}>
          [1] Zeng, Shuai, Ziting Mao, Yijie Ren, Duolin Wang, Dong Xu, and Trupti Joshi. "G2PDeep: a web-based deep-learning framework for quantitative phenotype prediction and discovery of genomic markers." Nucleic Acids Research 49, no. W1 (2021): W228-W236. https://doi.org/10.1093/nar/gkab407
        </p>
        <p style={{ fontSize: '14px' }}>
          [2] Liu, Yang, Duolin Wang, Fei He, Juexin Wang, Trupti Joshi, and Dong Xu. "Phenotype prediction and genome-wide association study using deep convolutional neural network of soybean." Frontiers in genetics 10 (2019): 1091. https://doi.org/10.3389/fgene.2019.01091
        </p>
      </div>
      <div style={{ width: '25%', display: 'inline-block', padding: '10px' }}>
        <h5 style={{ fontWeight: '400' }}>Alliance & Collaboration</h5>
        <p style={{ fontSize: '14px' }}>
          <Link to="/contact" > Contact Us</Link></p>
          <div className="image-overlay">
          {/* <a href='https://clustrmaps.com/site/1bv10'  title='Visit tracker'><img src='//clustrmaps.com/map_v2.png?cl=ffffff&w=300&t=tt&d=bRFZsuJs6L8qmndDWinwk1nEgN7vT9nmqhCBouRUzL0'/></a>
          <img src='//clustrmaps.com/map_v2.png?cl=ffffff&w=300&t=tt&d=bRFZsuJs6L8qmndDWinwk1nEgN7vT9nmqhCBouRUzL0' style={{ width: '100%'}}/> */}
          <a href='https://clustrmaps.com/site/1bvn3'  title='Visit tracker'><img src='//clustrmaps.com/map_v2.png?cl=ffffff&w=a&t=tt&d=iVbyvnk7wFykQeqSa7MD79ocpVvdaDYzS3X1J6y_vnA&co=3a90cc&ct=ffffff' style={{ width: '100%'}}/></a>
          <div className="overlay-text">
            <p>Total Project: {Projects}</p>
            <p>Total Models: {Models}</p>
            <p>Total Datasets: {Datasets}</p>
          </div>
        </div>
      </div>
    </div >
  )
}
