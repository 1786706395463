import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton, Collapse, Backdrop, CircularProgress, Button, Card, CardActions, CardContent, FormLabel, TextField, Typography, Link } from '@mui/material';
import { useState } from 'react';
import { formHeadingCss } from './util/formHeadingCss';
import { formSubmitButtonCss } from './util/formSubmitButtonCss';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '80%',
        backgroundColor: "aliceblue !important",
        alignContent: 'left',
        textAlign: 'left',
        margin: 'auto',
        marginBottom: '1%',
        marginTop: theme.spacing(2),
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: '2%'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        float: 'right',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

export default function LiteratureEvidence() {

    const [loading, setLoading] = useState(false);
    const [searchWord, setSearchWord] = useState("");
    const [publicationResults, setPublicationResults] = useState([]);

    const submitProject = () => {
        let searchWords = searchWord.split(",");
        let text = "";
        searchWords.map((word) => {
            text = text + word.trim() + "+";
        })
        text = text.substring(0, text.length - 1);

        setLoading(true);
        setSearchWord("");
        fetchPublicationResults(text);
    }

    const fetchPublicationResults = (text) => {
        fetch(`https://api.semanticscholar.org/graph/v1/paper/search?query=${text}&offset=0&limit=20&fields=externalIds,abstract,title,url,fieldsOfStudy`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response =>
                response.json()
            )
            .then(data => {
                setPublicationResults(data.data);
                setLoading(false);
                return data;
            });

    }

    const classes = useStyles();
    const [expandedCardId, setExpandedCardId] = useState(null);

    const handleClick = (index) => {
        if (expandedCardId === index) {
            setExpandedCardId(null);
        } else {
            setExpandedCardId(index);
        }
    };


    return (
        <>
            <div>
                <h3 style={{
                    textAlign: "justify"
                }}> Publications</h3>
                <div style={{ textAlign: "justify" }}>
                    <ul>
                        <li>All publications related to the search words</li>
                    </ul>
                </div>
                <Typography
                    style={formHeadingCss}>
                    Publications
                </Typography>
                <Card style={{
                    width: '50%',
                    alignContent: 'left',
                    textAlign: 'left',
                    margin: 'auto',
                    marginBottom: '2%',
                    boxShadow: ' 0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)',
                    fontWeight: '500'
                }}>
                    <CardContent>
                        <FormLabel> Please Enter Comma Seperated Keywords to Search </FormLabel>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            value={searchWord}
                            onChange={e => { setSearchWord(e.target.value) }} />
                    </CardContent>

                    <CardActions>
                        <Button style={formSubmitButtonCss} onClick={submitProject} size="large" disabled={searchWord == ""}>Sumbit</Button>
                    </CardActions>

                </Card >
            </div >

            <div>
                {loading ? (<div><Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                    <Typography> Dont refresh while fetching results </Typography>
                </Backdrop></div>) : ''}
            </div>

            <div>
                {publicationResults.length == 0 ? ("") : (publicationResults.map((result, index) => {
                    return (<div key={index}>

                        <Card className={classes.root}>
                            <CardContent>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    DOI: {result.externalIds.DOI}
                                </Typography>
                                <Typography variant="h7" component="h5" style={{
                                    marginBottom: '1%',
                                }}>
                                    {result.title}
                                </Typography>
                                {(result.fieldsOfStudy === null) ? (<Typography className={classes.pos} color="textSecondary">
                                    Not Available
                                </Typography>) : (<Typography className={classes.pos} color="textSecondary">
                                    Fields of Study : {result.fieldsOfStudy.toString()}
                                </Typography>)}
                                <IconButton
                                    className={`${classes.expand} ${(index === expandedCardId) ? classes.expandOpen : ''}`}
                                    onClick={() => handleClick(index)}
                                    aria-expanded={index === expandedCardId}
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            </CardContent>
                            <Collapse in={index === expandedCardId} timeout="auto" unmountOnExit>
                                <CardContent>
                                    {(result.abstract === null) ? (<Typography paragraph>
                                        Unable to fetch details. Please click on Links for further information.
                                    </Typography>) : (<Typography paragraph>
                                        {result.abstract}
                                    </Typography>)}
                                    <Link target="_blank" href={result.url}>Link 1</Link>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <Link target="_blank" href={`https://doi.org/${result.externalIds.DOI}`}>Link 2</Link>
                                </CardContent>
                            </Collapse>
                        </Card>
                    </div>)
                }))}
            </div>
        </>
    )
}
