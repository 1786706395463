import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

export default function DatagridDemo({ data, columns, handleCellClick, cellClassName }) {
    const [pageSize, setPageSize] = React.useState(5);

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={data}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onCellClick={handleCellClick}
                getCellClassName={cellClassName}
                hideFooterSelectedRowCount
                rowsPerPageOptions={[5, 10, 15]}
                pagination
                autoPageSize
            />
        </div>
    );
}
