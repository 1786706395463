export const imageStyleInUserGuide = {
    border: '1px solid #555',
    width: '40%',
    marginBottom: '20px',
};
export const imageStyleInModal = {
    border: '3px solid #555',
    width: '100%',
    marginBottom: '20px',
};
