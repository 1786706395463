import { useEffect, useRef, useState } from 'react';
import "../../App.css";

// Import Material Icons
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Backdrop, CircularProgress, Typography } from '@mui/material';
import { MDBDataTableV5 } from 'mdbreact';
import { useNavigate } from 'react-router-dom';
import { logoutFakeUser } from '../Auth/logoutFakeUser';
import { datasetConfirmationDialogFunc } from "../util/confirmationDialogUtil";
import { datasetTypeRepresentationBlock } from "../util/datasetTypeRepresentationBlock";
import { DetailsDialogTable } from '../util/DetailsDialogTable';
import { StudyresultsLegendsDiv } from "../util/LegendsDiv";
import { datasetPrivateTableMbColumns, datasetTypeKeyValues } from "../util/tableColumnsDetails";
import { ExtractDatasetDataToDisplay } from '../util/ExtractDataToDisplay';

export default function PrivateTestDataset({ setToken }) {

    let navigate = useNavigate();

    const [datasourceType, setDatasourceType] = useState("all");

    const token = sessionStorage.getItem('token');
    const didMount = useRef(true);

    useEffect(() => {

        if (didMount.current) {
            getPrivateDatasetInfo(datasourceType)
            didMount.current = false;
        }

    }, [datasourceType])

    const [datasetInfo, setDatasetInfo] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [makePublicDatasetName, setMakePublicDatasetName] = useState("");
    const [makePublicDatasetId, setMakePublicDatasetId] = useState("");
    const [displayRowData, setDisplayRowData] = useState([]);

    const handleClickOpen = (event) => {
        setOpenDialog(false);
        setConfirmationOpen(true);
        setMakePublicDatasetName(event.target.value);
        setMakePublicDatasetId(event.target.id);
    };

    const handleCloseAlert = () => {
        handleClose();
        setMakePublicDatasetId("");
        setMakePublicDatasetName("");
        setConfirmationOpen(false);
    };

    const getPrivateDatasetInfo = (datasource) => {

        fetch(`/api/datasets/retrieve_dataset_info?datasource_type=${datasource}`,
            {
                headers: { "Authorization": `Bearer ${token}` }
            })
            .then(res => res.json())
            .then(data => {

                if (!data.detail == '') {
                    logoutFakeUser(setToken, navigate);
                }

                data.message.forEach(element => {
                    element.dataset_type_key = datasetTypeRepresentationBlock(datasetTypeKeyValues[element.dataset_type_key.trim()])
                });

                setDatasetInfo(data.message);
                return data;
            })
    }

    const makeDatasetPublic = () => {

        setOpenDialog(false);
        setConfirmationOpen(false);
        setDataLoading(true);

        let myHeaders = new Headers();
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        setTimeout(() => {

            var raw = JSON.stringify({
                "dataset_info_id": makePublicDatasetId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow',
            };

            fetch('/api/datasets/make_dataset_public/', requestOptions)
                .then(res => res.json())
                .then(result => {
                    {
                        setDataLoading(false);
                    }
                })
                .catch(reqErr => console.error(reqErr));
            window.location.reload();
        }, 5000);
    }

    const dataSourcehandler = (e) => {
        setDatasourceType(e.target.value);
    }

    const confirmationDialog = datasetConfirmationDialogFunc(confirmationOpen, handleCloseAlert, makeDatasetPublic)

    const addButtonsToRows = (rows) => {
        return rows.map((row) => {
            const info_id = row.dataset_info_id;
            const datasetName = row.dataset_name;
            return {
                ...row,
                clickEvent: () => handleRowClick(row),
                button: <button id={info_id} onClick={(event) => handleClickOpen(event)} value={datasetName} type='button' style={{
                    backgroundColor: 'rgb(42, 63, 84)',
                    color: 'white',
                    borderRadius: '5px'
                }}>Make Public</button>
            };
        });
    };


    const [openDialog, setOpenDialog] = useState(false);

    const handleOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleRowClick = (rowData) => {
        handleOpen();
        let data = ExtractDatasetDataToDisplay(rowData);
        setDisplayRowData(data);
    };

    const detailsDialog = DetailsDialogTable("Dataset Information", "private", openDialog, handleClose, displayRowData, token);

    return (
        <div className="App wrapper" style={{
            padding: '1%',
            boxShadow: ' 0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)'
        }}>
            <h4 style={{
                fontWeight: '400',
                color: 'rgb(42, 63, 84)',
                textAlign: 'left',

            }}> Private Datasets Summary</h4>

            {detailsDialog}

            <FormControl>
                <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="all"
                    name="radio-buttons-group"
                    onChange={e => { dataSourcehandler(e) }}
                >
                    <FormControlLabel value="all" control={<Radio />} label="All" />
                    <FormControlLabel value="plant" control={<Radio />} label="Plants and Crops" />
                    <FormControlLabel value="animal" control={<Radio />} label="Animals and Pets" />
                    <FormControlLabel value="human" control={<Radio />} label="Humans and Diseases" />
                    <FormControlLabel value="virus" control={<Radio />} label="Microbes and Viruses" />
                </RadioGroup>
            </FormControl>

            <MDBDataTableV5
                hover
                bordered
                striped
                small
                entriesOptions={[5, 10, 15, 20]}
                entries={5}
                data={{ columns: datasetPrivateTableMbColumns, rows: addButtonsToRows(datasetInfo) }}
            />

            {confirmationDialog(makePublicDatasetName)}
            {StudyresultsLegendsDiv}

            <div>
                {dataLoading ? (<div><Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                    <Typography> Dont refresh while making dataset public</Typography>
                </Backdrop></div>) : ''}
            </div>
        </div>
    )
}