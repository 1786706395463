import React from 'react'

export default function Header() {
  return (
    <div>
            <h3 style={{
                fontFamily: 'Calibri',
                float: 'left',
                fontWeight: 'bold'
            }}>A Comprehensive Web Platform for Multi-Omics Data-Driven Phenotype Prediction and Marker Discovery</h3>
            <br></br>
            <br></br>
            <hr></hr>
        </div>

  )
}
