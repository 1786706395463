import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { useNavigate } from "react-router";

export default function UpdatePasswordStatus({ updateStatusEnable }) {

    const navigate = useNavigate();

    function reDirectToLogin() {
        setTimeout(() => {
            navigate("/login");
        }, 5000);
    }

    return (
        <div>
            <Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '80vh' }}>
                <Grid item xs={12}>
                    <Card style={{ boxShadow: ' 0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)', }}>
                        <CardHeader title={<Typography variant={"h3"} style={{
                            color: '#2A3F54',
                            fontWeight: 'bold'
                        }}>Update Password</Typography>} />
                        <CardContent >

                            {updateStatusEnable === 'SUCCESS' ? (<div>
                                <CheckCircleOutlineIcon style={{
                                    color: '#009933'
                                }} /><Typography >
                                    Update successfull ..!!
                                </Typography>
                            </div>) : (<div><DangerousIcon style={{
                                color: '#ff0000'
                            }} /><Typography >
                                    Update failed. Please retry ..!!
                                </Typography></div>)}
                            <br></br>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {reDirectToLogin()}
        </div>
    )
}
