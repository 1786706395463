import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../App.css";

// Import Material Icons
import { Backdrop, CircularProgress, Typography } from '@mui/material';
import { logoutFakeUser } from '../Auth/logoutFakeUser';
import { modelConfirmationDialogFunc } from '../util/confirmationDialogUtil';
import { ModelHparamLegendsDiv, StudyresultsLegendsDiv } from '../util/LegendsDiv';
import { datasetTypeKeyValues, modelPrivateMdbTableColumns } from '../util/tableColumnsDetails';
import { MDBDataTableV5 } from "mdbreact";
import { datasetTypeRepresentationBlock } from '../util/datasetTypeRepresentationBlock';
import { DetailsDialogTable } from '../util/DetailsDialogTable';
import { ExtractModelDataToDisplay } from '../util/ExtractDataToDisplay';

export default function PrivateTestModel({ setToken }) {

    const token = sessionStorage.getItem('token');
    let navigate = useNavigate();

    const didMount = useRef(true);

    useEffect(() => {
        if (didMount.current) {
            getPrivateModelInfo();
            didMount.current = false;
        }

    }, [])


    const [modelInfo, setModelInfo] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [makePublicModelName, setMakePublicModelName] = useState("");
    const [makePublicModelId, setMakePublicModelId] = useState("");

    const handleClickOpen = (event) => {
        setOpenDialog(false);
        setMakePublicModelName(event.target.value);
        setMakePublicModelId(event.target.id);
        setConfirmationOpen(true);
    };

    const handleCloseAlert = () => {
        handleClose();
        setMakePublicModelId("");
        setMakePublicModelName("");
        setConfirmationOpen(false);
    };

    const [displayRowData, setDisplayRowData] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);

    const handleOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const getPrivateModelInfo = () => {

        fetch(`api/modeling/retrieve_ml_model_info_summary`,
            {
                headers: { "Authorization": `Bearer ${token}` }
            })
            .then(res => res.json())
            .then(data => {

                if (!data.detail == '') {
                    logoutFakeUser(setToken, navigate);
                }
                data.message.forEach(element => {
                    element.ml_model_hparam_mode_key = datasetTypeRepresentationBlock(element.ml_model_hparam_mode_key.substring(0, 1) + "H")

                    let temp = [];
                    let values = element.dataset_type_key_vlsv.split("|");

                    values.forEach(element => {
                        let value = datasetTypeKeyValues[element.trim()];
                        temp.push(datasetTypeRepresentationBlock(value));
                    });
                    element.dataset_type_key_vlsv = temp
                });

                setModelInfo(data.message);
                return data;
            })
    }

    const makeModelPublic = () => {
        setOpenDialog(false);
        setConfirmationOpen(false);
        setDataLoading(true);

        let myHeaders = new Headers();
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        setTimeout(() => {

            var raw = JSON.stringify({
                "model_info_id": makePublicModelId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow',
            };
            fetch('/api/modeling/make_model_public/', requestOptions)
                .then(res => res.json())
                .then(result => {
                    {
                        setDataLoading(false);
                    }
                })
                .catch(reqErr => console.error(reqErr));

            window.location.reload();
        }, 5000);

    }

    const confirmationDialog = modelConfirmationDialogFunc(confirmationOpen, handleCloseAlert, makeModelPublic)


    const handleRowClick = (rowData) => {
        handleOpen();
        setDisplayRowData(ExtractModelDataToDisplay(rowData))
    };


    const addButtonsToRows = (rows) => {
        return rows.map((row) => {
            const info_id = row.model_info_id;
            const modelName = row.model_name;
            return {
                ...row,
                clickEvent: () => handleRowClick(row),
                button: <button id={info_id} onClick={(event) => handleClickOpen(event)} value={modelName} type='button' style={{
                    backgroundColor: 'rgb(42, 63, 84)',
                    color: 'white',
                    borderRadius: '5px'
                }}>Make Public</button>
            };
        });
    };

    const detailsDialog = DetailsDialogTable("Model Information", "private", openDialog, handleClose, displayRowData, token);

    return (
        <div className="App wrapper" style={{
            padding: '1%',
            boxShadow: ' 0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)'
        }}>

            <h4 style={{
                fontWeight: '400',
                color: 'rgb(42, 63, 84)',
                textAlign: 'left'
            }}> Private Models Summary</h4>

            {detailsDialog}

            <MDBDataTableV5
                hover
                bordered
                striped
                small
                entriesOptions={[5, 10, 15, 20]}
                entries={5}
                data={{ columns: modelPrivateMdbTableColumns, rows: addButtonsToRows(modelInfo) }}
            />
            {confirmationDialog(makePublicModelName)}
            {ModelHparamLegendsDiv}
            {StudyresultsLegendsDiv}

            <div>
                {dataLoading ? (<div><Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={dataLoading}
                >
                    <CircularProgress color="inherit" />
                    <Typography> Dont refresh while making Model public</Typography>
                </Backdrop></div>) : ''}
            </div>
        </div>
    )
}