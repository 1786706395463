import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import React from 'react';
import dataset_img from '../images/Datasets.png';
import model_list_img from '../images/Model_type.png';
import model_img from '../images/Models.png';
import model_type_img from '../images/Models_list.png';
import prediction_img from '../images/Prediction.png';
import prediction_analysis_img from '../images/Prediction_analysis.png';
import prediction_markers_img from '../images/Prediction_markers.png';
import prediction_phenotype_img from '../images/Prediction_phenotype.png';
import project_img from '../images/Projects.png';
import study_results_img from '../images/Study_results.png';
import study_results_img2 from '../images/Study_results_new.png';
import study_results_significant_markers_img from '../images/Study_results_significant_markers.png';
import study_results_significant_markers_img2 from '../images/Study_results_significant_markers_new.png';
import study_results_significant_markers_pathway_img from '../images/Study_results_significant_markers_pathway.png';
import study_results_table_img from '../images/Study_results_table.png';
import study_results_table_img2 from '../images/Study_results_table_new.png';
import { imageStyleInUserGuide } from './util/imageStyleInUserGuide';

export default function UserGuide() {

    const [expanded, setExpanded] = React.useState(false);

    const paper_url1 = 'https://academic.oup.com/nar/article/49/W1/W228/6284176'

    const paper_url2 = 'https://www.frontiersin.org/articles/10.3389/fgene.2019.01091/full';

    const handleChange = (panel) => (event, isExpanded) => {
        event.preventDefault();
        setExpanded(isExpanded ? panel : false);
    };

    const imgStyle = imageStyleInUserGuide

    return (
        <div style={{ textAlign: "justify" }}>

            <p>
                This document is for both new and experienced users of Quantitative phenotypes prediction and genotype markers
                discovery. Here are four features available for this web server, as listed in the left side of the website
                <strong><code>Introduction</code></strong>,
                <strong><code>Datasets</code></strong>,
                <strong><code>Experiments</code></strong>,
                <strong><code>Prediction &amp; discovery</code></strong>,
            </p>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        backgroundColor: "#dae7f5"
                    }}
                >
                    <Typography >1.Introduction</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                    <p>
        With the advances in molecular profiling technologies, the ability to observe millions of multi-omics data from cancer patients has grown markedly over the past decade. Genome-wide data 
        encompassing various molecular processes, such as gene expression, microRNA (miRNA) expression, protein expression, DNA methylation, single nucleotide polymorphisms (SNPs), 
        copy number variations (CNVs), can be obtained for the same set of samples, resulting in multi-omics data for numerous disease studies. The muti-omics data allows researchers to predict the 
        phenotypes and identify biomarkers that affect the diversities of phenotypes. To effectively take advantage of complementary information in multi-omics data, it is important to have a one-stop-
        shop platform for researchers to integrate multi-omics data, train customized deep-learning models using high-performance computing resources and estimate the potential biomarkers.
        </p>
        <p>The G2PDeep-v2 server is a comprehensive web-based platform powered by deep learning and 
        developed based on our previous works (<a href={paper_url1} target="_blank">paper1</a> and <a href={paper_url2} target="_blank">paper2</a>), providing phenotype prediction and 
        markers discovery for multi-omics data generated by high-throughput techniques in plants, animals, and humans. The server provides multiple services for researchers to create deep-
        learning models through an interactive interface and train these models using automated hyperparameter tuning algorithm on high-performance computing resources. It visualizes results 
        of phenotype and markers predicted by well-trained models. It also provides Gene Set Enrichment Analysis for the significant markers to provide insights into the mechanisms 
        underlying complex diseases and other biological phenomena.</p>
             </div>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        backgroundColor: '#f2f5fa'
                    }}
                >
                    <Typography>2.Datasets</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <div>
                        <p>In the datasets section, users can access publicly available datasets and private datasets created on their own. Currently, we have 23 different disease datasets from the TCGA data portal, 5 SoyNAM datasets, and 3 rice datasets. These datasets are RNA-seq, miRNA-seq, Protein expression, DNA Methylation, Zygosity, Copy Number Variation (CNV), and Single nucleotide polymorphisms (SNP). The publicly available datasets are open to all users including guests.  The private datasets can be accessed by the owner of the dataset. Following are the details to a create dataset.
                             </p>
                        <ul>
                            <li> Create new dataset (Shown in Figure 2.)
                                <ul>
                                    <p>
                                    Once the user login, a Create New Dataset button is shown in the top-right of the page. In the Create New Dataset page, a diagram tutorial and video are provided. The dataset name, data type (e.g., Gene Expression), datasets (links to dataset or uploading files), and type of data source are required inputs. The Create button would submit the inputs and the G2PDeep would validate the dataset. If the dataset is invalid, the website would provide the reason and suggestion to update the dataset. If everything went ok, you could access your dataset in the private dataset table.
                                      </p>
                                    <img
                                        style={imgStyle}
                                        src={dataset_img}
                                        alt="Create Dataset" />
                                </ul>
                            </li>
                        </ul>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        backgroundColor: "#dae7f5"
                    }}
                >
                    <Typography>3.Models</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <p>You can build a deep learning model through your dataset or our publicly available dataset.
                        </p>
                        <ul>
                            <li id="modelsSummary">Model Creation (Shown in Figure 3)
                                <p> Once the user login, a Model Creation button is shown in the top-right of the page. In the Model Creation page, the model’s name, data type for input datasets, task of the mode (e.g., classification), model (shown in Table 1), and Hyperparameter option (details shown in Table 2) are required inputs. </p>
                            </li>
                            <img
                                style={imgStyle}
                                src={model_type_img} 
                                alt='Model Summary '/>
                            <p> Table 1. Available models in G2PDeep. The number of inputs represents the number of different omics data as input of the model. The checkmark represents the model that can handle the classification or regression task.</p>
                            <img
                                style={imgStyle}
                                src={model_list_img} 
                                alt='Model Summary '/>
                            <p>Table 2. Details of the hyperparameter option.</p>
                            <img
                                style={imgStyle}
                                src={model_img}
                                alt='Model Summary ' />
                            <p> Figure 3. Diagram tutorial to create model.</p>
                        </ul>
                    </div>

                </AccordionDetails>
            </Accordion>


            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        backgroundColor: '#f2f5fa'
                    }}>
                    <Typography>4.Projects</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <div>
                        <p>You can train a model with corresponding datasets. The following are details to train a model.</p>
                        <ul>
                            <li>Project Creation (Shown in Figure 4)
                                <ul>
                                    <p>Once the user login, a Project Creation button is shown in the top-right of the page. In the Project Creation page, the project name, model, and training dataset are required. Once you choose a model, datasets are then listed in the options along with data type (e.g., gene expression and miRNA expression). The Hyperparameter Option is shown again in case users would like to change it.</p>
                                    <img
                                        style={imgStyle}
                                        src={project_img} 
                                        alt='Projects Summary '/>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        backgroundColor: "#dae7f5"
                    }}
                >
                    <Typography>5.Prediction & Discovery</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <div>
                        <p>Once you trained a model, you can use it to predict the phenotype and estimate the significant genes/markers.</p>
                        <ul>
                            <li>Prediction and Discovery (Shown in Figure 5.1)
                                <ul>
                                    <p>In the Prediction and Discovery page, the prediction name, project name, and corresponding datasets are required. The datasets can be uploaded via the web interface.</p>
                                    <img
                                        style={imgStyle}
                                        src={prediction_img} 
                                        alt='Prediction & Discovery '/>
                                    <p> Figure 5.1. diagram tutorial to make a prediction using test datasets.</p>
                                </ul>
                            </li>
                            <li> Predicted phenotype
                                <ul>
                                    <p>The phenotype for each sample of the test dataset is predicted by a selected model. The distribution of predicted values is shown in form of a plot. Predicted results can be downloaded by clicking a link below the distribution plot.</p>
                                    <img
                                        style={imgStyle}
                                        src={prediction_phenotype_img} 
                                        alt='Prediction & Discovery '/>
                                    <p> Figure 5.2. the distribution of predicted values.</p>
                                </ul>
                            </li>
                            <li> Markers significance
                                <ul>
                                    <p>The significant markers are shown in the scatter plot. Users can show the top significant markers by the threshold. The markers can be downloaded by clicking the link.</p>
                                    <img
                                        style={imgStyle}
                                        src={prediction_markers_img} 
                                        alt='Prediction & Discovery '/>
                                    <p>Figure 5.3. Markers significance.</p>
                                </ul>
                            </li>
                            <li> Annotation for significant markers
                                <ul>
                                    <p>We currently have the results of Gene Set Enrichment Analysis (GSEA) on the KEGG and the Reactome pathway for significant markers (Figure 5.4).</p>
                                    <img
                                        style={imgStyle}
                                        src={prediction_analysis_img} 
                                        alt='Prediction & Discovery '/>
                                    <p>Figure 5.4. GSEA results for significant markers.</p>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        backgroundColor: '#f2f5fa'
                    }}>
                    <Typography>6.Study Results</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <div>
                        <p>Currently we have estimated significant genes/markers for 23 different diseases (Shown in Table 3) from TCGA data portal, 5 SoyNAM datasets, and 3 rice datasets.</p>
                        <img
                            style={imgStyle}
                            src={study_results_table_img2} 
                            alt='Study Results '/>
                        <p>Table 3. Study results and their abbreviations</p>
                        <ul>
                            <li>Summary of study results (Shown in Figure 6.1)
                                <ul>
                                    <p>Users can choose disease and percentage (threshold) to show the results of significant markers.</p>
                                    <img
                                        style={imgStyle}
                                        src={study_results_img2} 
                                        alt='Study Results '/>
                                    <p>Figure 6.1. diagram tutorial to retrieve study results.</p>
                                </ul>
                            </li>
                            <li>Significant markers (Shown in Figure 6.2 and Figure 6.3)
                                <ul>
                                    <p>The significant markers are shown in an Upset plot and can be downloaded by a link. Their annotations for significant biomarkers are shown in tables. </p>
                                    <img
                                        style={imgStyle}
                                        src={study_results_significant_markers_img2} 
                                        alt='Study Results '/>
                                    <p>Figure 6.2. results for significant markers.</p>
                                    <img
                                        style={imgStyle}
                                        src={study_results_significant_markers_pathway_img} 
                                        alt='Study Results '/>
                                    <p>Figure 6.3. Details about significant markers with pathway information</p>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div >
    )
}
