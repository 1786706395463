import { Card, CardActions, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from "react-router-dom";
import PrivateTestDataset from './PrivateTestDataset';
import PublicTestDataset from './PublicTestDataset';

export default function Datasets({ setToken }) {

    const token = sessionStorage.getItem('token');

    let navigate = useNavigate();

    const loginPageRedirect = () => {
        navigate("/login");
    }

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={6} >
                    <div style={{ textAlign: "justify" }}>
                        <h3 style={{
                            fontWeight: '400',
                            color: 'rgb(42, 63, 84)',
                            textAlign: 'left'
                        }}>Summary of Datasets</h3>
                        <p>Instruction:</p>
                        <ul>
                            <li>The publicly available datasets are originally from TCGA.</li>
                            <li>The private dataset can be created after <Link to="/login" > login</Link>.</li>
                        </ul>
                        <br></br>
                        <br></br>
                    </div>
                </Grid>
                {token != null ?
                    <Grid item xs={6} >
                        <Link to='/create_dataset'>
                            <button type='button' style={{
                                margin: '10%',
                                padding: '5px',
                                backgroundColor: '#2A3F54',
                                color: 'white',
                                float: 'right',
                                borderRadius: '5px'
                            }}>CREATE NEW DATASET</button>
                        </Link>

                    </Grid>
                    : ''}
            </Grid>

            <div>
                <PublicTestDataset />
            </div>
            <br></br>
            <br></br>
            <div>
                {token != null ? (
                    <PrivateTestDataset setToken={setToken} />
                ) : (
                    <Card style={{ border: '1px solid' }}>
                        <CardHeader title={<Typography variant={"h6"} style={{
                            float: 'left'
                        }}>Personal Datasets Summary</Typography>} />
                        <Divider></Divider>
                        <CardContent> Please login to see your dataset information</CardContent>
                        <CardActions>
                            {/* <Link to="/login"> */}
                            <Button style={{
                                margin: 'auto',
                                backgroundColor: '#2A3F54',
                                color: 'white'
                            }} onClick={loginPageRedirect} >Login</Button>
                            {/* </Link> */}
                        </CardActions>
                    </Card>)}
            </div>

        </>
    )
}