import MaterialTable from "@material-table/core";
import React from 'react';
import "../../App.css";

// Import Material Icons
import { materialtableIcons } from '../util/materialtableIcons';
import { predictionInfoTablecolumns } from '../util/tableColumnsDetails';

export default function ValueTable({ data }) {

    const tableRef = React.createRef();

    return (
            <div className="App wrapper" >
                <MaterialTable
                    title="Summary"
                    tableRef={tableRef}
                    icons={materialtableIcons}
                    columns={predictionInfoTablecolumns}
                    data={data}

                    options={{
                        sorting: true,
                        headerStyle: {
                            backgroundColor: "#2A3F54",
                            color: "#FFF",
                            fontSize: "17px",
                            fontWeight: "bold",
                        },
                        rowStyle: rowData => ({
                            backgroundColor: !!rowData.parentOnly ? "#2A3F54" : "#dae7f5"
                        }),
                        cellStyle: { border: '1px solid #050000' },
                        actionsColumnIndex: -1,
                        paging: true,
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 15, 20],
                        paginationType: "stepped",
                        emptyRowsWhenPaging: false,
                        numberOfPagesAround: 3
                    }}

                />
            </div>
    )
}
