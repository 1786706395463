import { Button, Card, CardActions, CardContent, CardHeader, Checkbox, Grid, TextField, Typography } from '@material-ui/core';
import { Alert, Autocomplete } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";
import validator from 'validator';

async function signupUser(credentials) {

    console.log(JSON.stringify(credentials));
    return fetch('/api/auth/signup', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

export default function Signup() {

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [univName, setUnivName] = useState('');
    const [customUnivName, setCustomUnivName] = useState('');
    const [password, setPassword] = useState('');
    const [captchaResult, setCaptchaResult] = useState(false);
    const [univList, setUnivList] = useState([]);
    const [failAlert, setFailAlert] = useState(false);
    const [failMessage, setFailMessage] = useState("Error while creating account. Please retry..!!");

    let navigate = useNavigate();

    const didMount = useRef(true);

    useEffect(() => {

        if (didMount.current) {
            universitiesList();
            didMount.current = false;
        }

    }, [])

    async function handleSubmit(event) {
        event.preventDefault();

        if (!validator.isEmail(email)) {
            event.target.reset();
            setFailMessage('Invalid Email ... Please enter a valid email')
            setFailAlert(true);
            return;
        }

        if (!validator.isStrongPassword(password, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setFailMessage('Password must contain Uppercase, Lowercase and a Special character with min length 8');
            setFailAlert(true);
            setPassword("");
            return;
        }

        const response = await signupUser({
            firstname,
            lastname,
            email,
            "univName": (univName === "Other" ? ("Other-" + customUnivName) : univName),
            password
        });

        if (response.status === 'FAILURE') {
            event.target.reset();
            setFailAlert(true);
            return;
        }

        navigate("/login");
    }

    const handleRecaptcha = (value) => {

        fetch('/api/auth/recaptcha/', {
            method: 'POST',
            body: JSON.stringify({ 'captcha_value': value }),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                setCaptchaResult(data.captcha.success)
            })
    }

    const universitiesList = () => {
        fetch('/api/information/get_univeristies_list/', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                var ll = [];
                Object.values(data.result).forEach((value) => {
                    ll.push(value.name + " - " + value.alpha_two_code + "(" + value.domains + ")");
                });

                ll.push("Other");
                setUnivList(ll);
                return data;
            })
    }

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    return (
        <>
            <div>
                <form onSubmit={handleSubmit}>
                    <Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '80vh' }}>
                        <Grid item xs={12}>
                            <Card style={{
                                boxShadow: ' 0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)',
                            }}>
                                <CardHeader title={<Typography component={'span'} variant={"h3"} style={{
                                    color: '#2A3F54',
                                    fontWeight: 'bold'
                                }}>Sign up</Typography>} />
                                <CardContent >
                                    <Typography component={'span'} style={{
                                        color: '#2A3F54'
                                    }} sx={{ fontSize: 14 }} color="secondary" >
                                        Sign and get started ..!!
                                    </Typography><br></br><br></br>
                                    <Typography component={'span'}>
                                        <TextField label="First Name" fullWidth required variant="outlined" onChange={e => setFirstname(e.target.value)} />
                                    </Typography><br></br><br></br>
                                    <Typography component={'span'}>
                                        <TextField label="Last Name" fullWidth required variant="outlined" onChange={e => setLastname(e.target.value)} />
                                    </Typography><br></br><br></br>
                                    <Typography component={'span'}>
                                        <TextField label="Email" fullWidth required variant="outlined" onChange={e => setEmail(e.target.value)} />
                                    </Typography><br></br><br></br>
                                    <Autocomplete
                                        disablePortal
                                        fullWidth
                                        options={univList}
                                        sx={{ width: '100%' }}
                                        onChange={(event, value) => setUnivName(value)}
                                        renderInput={(params) => <TextField required {...params}
                                            label="Institution" />}
                                    />
                                    {
                                        univName === "Other" ? (<>
                                            <br></br><br></br>
                                            <TextField type="text" label='Enter Institution name' fullWidth required variant="outlined" onChange={e => setCustomUnivName(e.target.value)} />
                                        </>) : ''
                                    }
                                    <br></br><br></br>
                                    <Typography component={'span'} display="inline">
                                        <TextField type="password" label="Password" fullWidth required variant="outlined" onChange={e => setPassword(e.target.value)} />
                                    </Typography>
                                    <Typography component={'span'} display="inline" style={{ float: 'left' }}>
                                        <Checkbox {...label} />Accept terms and conditions
                                    </Typography>
                                </CardContent>
                                <br></br><br></br>
                                <CardActions>
                                    <ReCAPTCHA style={{ margin: 'auto' }} sitekey="6LdtxcMnAAAAAKUTm75ojuuxpPVy9zQaAjBTrrtB"
                                        onChange={handleRecaptcha}
                                    />
                                </CardActions>
                                <CardActions>
                                    <Button style={{
                                        margin: 'auto',
                                        backgroundColor: '#2A3F54',
                                        color: 'white'
                                    }} type="submit" size="large" 
                                    disabled={!captchaResult}
                                    >Signup</Button>
                                </CardActions>
                            </Card>
                            {failAlert ? (<Alert severity="error" >{failMessage}</Alert>) : ''}
                            <br></br>
                            <Typography display="inline">
                                Already an User? <Link to="/login">Login</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </form>
            </ div></>
    )
}
