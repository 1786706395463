import React from 'react';

export function datasetTypeRepresentationBlock(value) {
    return <div style={{
        display: 'inline-block',
        width: "25px",
        height: "25px",
        border: '1px solid black',
        textAlign: 'center',
        verticalAlign: 'middle',
        marginRight: '4px',
    }}>
        <p style={{
            margin: 0,
            fontSize: '14px',
            fontWeight: 'bolder',
            fontStyle: 'italic'
        }}>{value}</p>

    </div >;
}
