import { useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import ForgotPassword from './components/Auth/ForgotPassword';
import Login from './components/Auth/Login';
import UpdatePassword from './components/Auth/UpdatePassword';
import UpdatePasswordStatus from './components/Auth/UpdatePasswordStatus';
import ContactInfo from './components/ContactInfo/ContactInfo';
import CreateDS from './components/Datasets/CreateDS';
import { DatasetInfo } from './components/Datasets/DatasetInfo';
import Datasets from './components/Datasets/Datasets';
import Footer from './components/Footer';
import Header from './components/Header';
import Introduction from './components/Introduction/Introduction';
import LiteratureEvidence from './components/LiteratureEvidence';
import CreateModel from './components/Model/CreateModel';
import Models from './components/Model/Models';
import Navigator from './components/Navigator';
import CreatePrediction from './components/Prediction/CreatePrediction';
import Prediction from './components/Prediction/Prediction';
import PredictionInfo from './components/Prediction/PredictionInfo';
import CreateProject from './components/Projects/CreateProject';
import ProjectInfo from './components/Projects/ProjectInfo';
import Projects from './components/Projects/Projects';
import Signup from './components/Signup';
import StudyResultsDemo from './components/StudyResults/StudyResultsDemo';
import UserGuide from './components/UserGuide';
import GitHub from './components/GitHub';
import SideNavigationBar from './components/SideNavigationBar';
import Workshop from './components/Workshop'

function App() {

  const getToken = () => {
    const userToken = sessionStorage.getItem('token');
    return userToken?.token
  };

  const [token, setToken] = useState(getToken());
  const [emailForgot, setEmailForgot] = useState();
  const [updateStatusEnable, setUpdateStatusEnable] = useState("FAILURE");

  return (
    <Router>
      <div className='App' style={{ paddingRight: 20 }} >
        <div className='content'>
          <div className='navigationBar'>
            {/* <Navigator token={token} setToken={setToken} /> */}
            <SideNavigationBar token={token} setToken={setToken} />
          </div>
          <div className='right-container'>
            <div>
              <Header />

              <Routes>
                <Route path="/" element={<Introduction />} />
                <Route path="/introduction" element={<Introduction />} />
                <Route path="/contact" element={<ContactInfo />} />
                {/* <Route path="/test1" element={<DatagridDemo />} /> */}
                <Route path="/publish" element={<LiteratureEvidence />} />
                <Route path="/datasets" element={<Datasets setToken={setToken} />} />
                <Route path="/create_dataset" element={<CreateDS setToken={setToken} />} />
                {/* <Route path="/create_dataset" element={<CreateDataset token={token} />} /> */}
                <Route path="/models" element={<Models setToken={setToken} />} />
                <Route path="/projects" element={<Projects setToken={setToken} />} />
                <Route path="/create_project" element={<CreateProject setToken={setToken} />} />
                <Route path="/predict" element={<Prediction setToken={setToken} />} />
                <Route path="/create_prediction" element={<CreatePrediction setToken={setToken} />} />
                <Route path="/prediction_info" element={<PredictionInfo token={token} />} />
                <Route path="/study_results" element={<StudyResultsDemo />} />
                <Route path="/user_guide" element={<UserGuide />} />
                <Route path="/github" element={<GitHub />} />
                <Route path="/login" element={<Login setToken={setToken} />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/dataset_info" element={<DatasetInfo />} />
                <Route path="/project_info" element={<ProjectInfo setToken={setToken} />} />
                <Route path="/forgot_password" element={<ForgotPassword setEmailForgot={setEmailForgot} />} />
                <Route path="/update_password" element={<UpdatePassword emailForgot={emailForgot} setUpdateStatusEnable={setUpdateStatusEnable} />} />
                <Route path="/update_password_status" element={<UpdatePasswordStatus updateStatusEnable={updateStatusEnable} />} />
                <Route path="/create_model" element={<CreateModel setToken={setToken} />} />
                <Route path="/workshop" element={<Workshop />} />

              </Routes>
            </div>
            <div className='footerContainer'>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}
// }

export default App;
