import { IconButton, Typography } from '@material-ui/core';
import { Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { dataDisplay } from './dataDisplay';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';

export function DetailsDialogTable(heading, type, openDialog, handleClose, displayRowData, token) {

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const [dataLoading, setDataLoading] = useState(false);

  const handleDeleteIconClick = () => {
    setShowConfirmationDialog(true);
  };

  const handleConfirmDelete = () => {

    setDataLoading(true);
   
    let myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
   
    setTimeout(() => {

      let deleteApi;
      if (heading === 'Model Information') {
        deleteApi = '/api/modeling/delete_private_ml_model/';
        var raw = JSON.stringify({
          "model_info_id": displayRowData['Model id']
      });
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
      };
      } 
    else {
      deleteApi = '/api/datasets/delete_private_datasets/';
      var raw = JSON.stringify({
        "dataset_info_id": displayRowData['Dataset id']
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
  };
    }

      fetch(deleteApi, requestOptions)
          .then(res => res.json())
          .then(result => {
            {
                setDataLoading(false);
            }
        })
          .catch(reqErr => console.error(reqErr));

          window.location.reload();
  }, 5000);
    
    setShowConfirmationDialog(false);
    handleClose();
  };

  const handleCancelDelete = () => {
    // Close the confirmation dialog
    setShowConfirmationDialog(false);
  };

  return (
    !displayRowData == "" && (
      <div>
        <Dialog open={openDialog} style={{ right: '0%' }}>
          <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              style={{
                width: '60%',
                margin: 'auto',
                fontWeight: '400',
                color: 'rgb(42, 63, 84)',
                fontSize: '25px',
              }}
              color="textPrimary"
              gutterBottom
              align="center"
            >
              {heading}
            </Typography>
            {type === 'private' && (
              <>
                <IconButton aria-label="delete" onClick={handleDeleteIconClick}>
                  <DeleteIcon />
                </IconButton>
                <Dialog open={showConfirmationDialog}>
                  <DialogTitle>Confirm Delete</DialogTitle>
                  <DialogContent>
                    <Typography>Are you sure you want to delete this item?</Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleConfirmDelete} color="secondary">
                      Delete
                    </Button>
                    <Button onClick={handleCancelDelete} color="primary">
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>{dataDisplay(displayRowData)}</DialogContent>
        </Dialog>
        <div>
                {dataLoading ? (<div><Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={dataLoading}
                >
                    <CircularProgress color="inherit" />
                    <Typography> Please donot refresh while deleting</Typography>
                </Backdrop></div>) : ''}
            </div>
      </div>
    )
  );
}
