import { useEffect, useState, useRef } from 'react';
import "../../App.css";

// Import Material Icons
import { MDBDataTableV5 } from "mdbreact";
import { datasetTypeKeyValues, modelPublicMdbTableColumns } from "../util/tableColumnsDetails";
import { datasetTypeRepresentationBlock } from '../util/datasetTypeRepresentationBlock';
import { ModelHparamLegendsDiv, StudyresultsLegendsDiv } from '../util/LegendsDiv';
import { DetailsDialogTable } from '../util/DetailsDialogTable';
import { ExtractModelDataToDisplay } from '../util/ExtractDataToDisplay';

export default function PublicTestModel() {
    const didMount = useRef(true);

    useEffect(() => {
        if (didMount.current) {
            getPublicModelInfo();
            didMount.current = false;
        }
    }, [])

    const [modelInfo, setModelInfo] = useState([]);
    const [displayRowData, setDisplayRowData] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);

    const handleOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const getPublicModelInfo = () => {

        fetch(`api/modeling/retrieve_ml_model_info_summary/`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {

                data.message.forEach(element => {
                    element.ml_model_hparam_mode_key = datasetTypeRepresentationBlock(element.ml_model_hparam_mode_key.substring(0, 1) + "H")

                    let temp = [];
                    let values = element.dataset_type_key_vlsv.split("|");

                    values.forEach(element => {
                        let value = datasetTypeKeyValues[element.trim()];
                        temp.push(datasetTypeRepresentationBlock(value));
                    });
                    element.dataset_type_key_vlsv = temp
                });
                setModelInfo(data.message);
                return data;
            })
    }


    const handleRowClick = (rowData) => {
        handleOpen();
        setDisplayRowData(ExtractModelDataToDisplay(rowData));
    };

    const addDatatoRows = (rows) => {
        return rows.map((row) => {

            return {
                ...row,
                clickEvent: () => handleRowClick(row)
            };
        });
    }

    const detailsDialog = DetailsDialogTable("Model Information", "public", openDialog, handleClose, displayRowData);

    return (
        <div className="App wrapper" style={{ padding: '1%', boxShadow: ' 0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)' }}>
            <h4 style={{
                fontWeight: '400',
                color: 'rgb(42, 63, 84)',
                textAlign: 'left'
            }}> Public Models Summary</h4>

            {detailsDialog}

            <MDBDataTableV5
                hover
                bordered
                striped
                small
                entriesOptions={[5, 10, 15, 20]}
                entries={5}
                data={{ columns: modelPublicMdbTableColumns, rows: addDatatoRows(modelInfo) }}
            />
            {ModelHparamLegendsDiv}
            {StudyresultsLegendsDiv}
        </div >
    )
}
