import { useEffect, useRef, useState } from 'react';
import "../../App.css";

// Import Material Icons
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { MDBDataTableV5 } from "mdbreact";
import { useNavigate } from 'react-router-dom';
import { StudyresultsLegendsDiv } from "../util/LegendsDiv";
import { datasetTypeRepresentationBlock } from "../util/datasetTypeRepresentationBlock";
import { DetailsDialogTable } from '../util/DetailsDialogTable';
import { datasetPublicTableMbColumns, datasetTypeKeyValues } from "../util/tableColumnsDetails";
import { ExtractDatasetDataToDisplay } from '../util/ExtractDataToDisplay';

export default function PublicDatasetTable() {

    const [datasourceType, setDatasourceType] = useState("all");
    const didMount = useRef(true);

    useEffect(() => {

        if (didMount.current) {
            getPublicDatasetInfo(datasourceType)
            didMount.current = false;
        }

    }, [datasourceType])

    const [datasetInfo, setDatasetInfo] = useState([]);
    const [displayRowData, setDisplayRowData] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);

    const handleOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    let navigate = useNavigate();

    const getPublicDatasetInfo = (datasource) => {

        fetch(`/api/datasets/retrieve_dataset_info?datasource_type=${datasource}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                data.message.forEach(element => {
                    element.dataset_type_key = datasetTypeRepresentationBlock(datasetTypeKeyValues[element.dataset_type_key.trim()])
                });
                setDatasetInfo(data.message);
                return data;
            })
    }

    const dataSourcehandler = (e) => {
        setDatasourceType(e.target.value);
        didMount.current = true;

    }

    const handleRowClick = (rowData) => {
        handleOpen();

        let data = ExtractDatasetDataToDisplay(rowData);
        setDisplayRowData(data);
    };

    const addDatatoRows = (rows) => {
        return rows.map((row) => {

            return {
                ...row,
                clickEvent: () => handleRowClick(row)
            };
        });
    }

    const detailsDialog = DetailsDialogTable("Dataset Information", "public", openDialog, handleClose, displayRowData);

    return (
        <div className="App wrapper" style={{
            padding: '1%',
            boxShadow: ' 0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)'
        }}>

            {detailsDialog}

            <h4 style={{
                fontWeight: '400',
                color: 'rgb(42, 63, 84)',
                textAlign: 'left'
            }}> Public Datasets Summary</h4>
            <FormControl >
                <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="all"
                    name="radio-buttons-group"
                    onChange={e => { dataSourcehandler(e) }}
                >
                    <FormControlLabel value="all" control={<Radio />} label="All" />
                    <FormControlLabel value="plant" control={<Radio />} label="Plants and Crops" />
                    <FormControlLabel value="animal" control={<Radio />} label="Animals and Pets" />
                    <FormControlLabel value="human" control={<Radio />} label="Humans and Diseases" />
                    <FormControlLabel value="virus" control={<Radio />} label="Microbes and Viruses" />
                </RadioGroup>
            </FormControl>
            <MDBDataTableV5
                hover
                bordered
                striped
                small
                entriesOptions={[5, 10, 15, 20]}
                entries={5}
                data={{ columns: datasetPublicTableMbColumns, rows: addDatatoRows(datasetInfo) }}
            />
            {StudyresultsLegendsDiv}

        </div >
    )
}