import { useLocation } from "react-router-dom";

export function DatasetInfo() {
    let data = useLocation();

    return (
       
        <div style={{
            textAlign: 'justify'
        }}><div>
                <p>Instruction:</p>
                <ul>
                    <li>Users are able to modify the percentage of training and validation dataset.</li>
                    <li>Diagram tutorial is in here</li>
                </ul>
            </div>
            <h3> {data.state.row.dataset_name} dataset details</h3>
            <div>
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>Property</th>
                            <th>Value</th>
                        </tr>

                    </thead>
                    <tbody>

                        {
                            <>
                                <tr className="active-row">
                                    <td> Dataset name</td>
                                    <td> {data.state.row.dataset_name}</td>
                                </tr>
                                <tr className="active-row">
                                    <td> Dataset Type </td>
                                    <td> {data.state.row.dataset_type_name}</td>
                                </tr>
                                <tr className="active-row">
                                    <td> Data Source Type</td>
                                    <td> {data.state.row.datasource_type}</td>
                                </tr>
                                <tr className="active-row">
                                    <td> Description</td>
                                    <td> {data.state.row.description}</td>
                                </tr>
                                <tr className="active-row">
                                    <td> Created at</td>
                                    <td> {data.state.row.created_at}</td>
                                </tr>
                                <tr className="active-row">
                                    <td> Tags </td>
                                    <td> {data.state.row.tags}</td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
