import { Button, Card, CardActions, CardContent, CardHeader, Grid, TextField, Typography } from '@material-ui/core';
import { Alert } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';

async function sendOtp(credentials) {
    return fetch('/api/auth/sendotp', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}

export default function ForgotPassword({ setEmailForgot }) {

    const [email, setEmail] = useState();
    const [responseOTP, setResponseOTP] = useState();
    const [validateOTP, setValidateOTP] = useState();
    const [openOtpTab, setOpenOtpTab] = useState(false);
    const [otpAlert, setOtpAlert] = useState(false);

    const navigate = useNavigate();

    async function handleSendOTP(event) {
        event.preventDefault();
        setOpenOtpTab(true);
        const response = await sendOtp({
            email
        });
        console.log(response);
        setResponseOTP(response.message)
    }

    async function handleValidate(event) {
        event.preventDefault();

        if (validateOTP === responseOTP) {
            setEmailForgot(email);
            navigate("/update_password");
        } else {
            setOtpAlert(true);
        }
    }

    return (
        <div>
            <form >
                <Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '80vh' }}>
                    <Grid item xs={12}>
                        <Card style={{ boxShadow: ' 0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)', }}>
                            <CardHeader title={<Typography variant={"h3"} style={{
                                color: '#2A3F54',
                                fontWeight: 'bold'
                            }}>Forgot Password ..?</Typography>} />
                            <CardContent >
                                <Typography style={{
                                    color: '#2A3F54'
                                }} sx={{ fontSize: 14 }} color="secondary" >
                                    No need to worry ..!!
                                </Typography><br></br>
                                <Typography >
                                    <TextField label="Email" fullWidth required variant="outlined" onChange={e => setEmail(e.target.value)} />
                                </Typography><br></br>
                                {
                                    openOtpTab ? (<Typography >
                                        <TextField label="otp" fullWidth required variant="outlined" onChange={e => setValidateOTP(e.target.value)} />
                                    </Typography>) : ''
                                }
                                <br></br>
                            </CardContent>
                            <CardActions>
                                {!openOtpTab ? (<Button style={{
                                    margin: 'auto',
                                    backgroundColor: '#2A3F54',
                                    color: 'white'
                                }} onClick={handleSendOTP} size="large" >Send OTP</Button>)
                                    :
                                    (<Button style={{
                                        margin: 'auto',
                                        backgroundColor: '#2A3F54',
                                        color: 'white'
                                    }} onClick={handleValidate} size="large" >Submit</Button>)}

                            </CardActions>
                        </Card>
                        {otpAlert ? (<Alert severity="error">Please enter correct OTP</Alert>) : ''}
                    </Grid>
                </Grid>
            </form>
        </ div>
    )
}
