import React, { useState } from 'react'
import { Grid, ListItemIcon, StepContent, StepLabel } from '@mui/material';
import { Step, Stepper } from '@mui/material';
import { Button } from '@mui/material';
import workflow_intro from './workflow_intro.png'
import workflow_datasets from './workflow_datasets.png'
import workflow_experiment from './workflow_experiment.png'
import workflow_prediction from './workflow_prediction.png'
import dataset_example_img from './dataset_example.png'
import overview_img from './overview.png'
import gene_video from '../../images/gene.mp4'

export default function Introduction() {

  const [activeStep, setActiveStep] = useState(0);

  const paper_url1 = 'https://academic.oup.com/nar/article/49/W1/W228/6284176'

  const paper_url2 = 'https://www.frontiersin.org/articles/10.3389/fgene.2019.01091/full';

  function onChangeSteps(current) {
    setActiveStep(current);
  };

  let step_comp = (
    <div style={{ marginRight: '50px', marginTop: '2%' }}>
      <Stepper orientation="horizontal" activeStep={activeStep} >

        <Step >
          <StepLabel >
            <Button onClick={(e) => {
              onChangeSteps(0);
            }}>Introduction
            </Button>
          </StepLabel>
          {/* <StepContent>Workflow of G2PDeep.</StepContent> */}
        </Step>

        <Step>
          <StepLabel >
            <Button onClick={(e) => {
              onChangeSteps(1);
            }}>Datasets
            </Button>
          </StepLabel>
          {/* <StepContent>Upload and create dataset of SNP.</StepContent> */}
        </Step>

        <Step>
          <StepLabel >
            <Button onClick={(e) => {
              onChangeSteps(2);
            }}>Models
            </Button>
          </StepLabel>
          {/* <StepContent>create Models.</StepContent> */}
        </Step>

        <Step >
          <StepLabel >
            <Button onClick={(e) => {
              onChangeSteps(3);
            }}>Projects
            </Button>
          </StepLabel>
          {/* <StepContent> Build, train, analysis state-of-the-art deep learning models.</StepContent> */}
        </Step>

        <Step >
          <StepLabel >
            <Button onClick={(e) => {
              onChangeSteps(4);
            }}>Prediction & Biomarkers Discovery
            </Button>
          </StepLabel>
          {/* <StepContent>Predict quantitative phenotype trait and detect genotype markers.</StepContent> */}
        </Step>

        <Step>
          <StepLabel >
            <Button onClick={(e) => {
              onChangeSteps(5);
            }}>Study Results
            </Button>
          </StepLabel>
          {/* <StepContent>Results.</StepContent> */}
        </Step>

      </Stepper>
    </div>
  )

  let step_content = (
    <div style={{ paddingTop: '10px', textAlign: 'justify'}}>
      <div id='step_content_introduction' style={{ display: activeStep === 0 ? 'block' : 'none'}}>
        {/* <img src={workflow_intro} style={{ width: '100%' }}></img> */}
        <h4 style={{ paddingTop: '20px', paddingBottom: '10px', fontWeight: 'bold' }} > Introduction </h4>
        <p>
        With the advances in molecular profiling technologies, the ability to observe millions of multi-omics data from cancer patients has grown markedly over the past decade. Genome-wide data 
        encompassing various molecular processes, such as gene expression, microRNA (miRNA) expression, protein expression, DNA methylation, single nucleotide polymorphisms (SNPs), 
        copy number variations (CNVs), can be obtained for the same set of samples, resulting in multi-omics data for numerous disease studies. The muti-omics data allows researchers to predict the 
        phenotypes and identify biomarkers that affect the diversities of phenotypes. To effectively take advantage of complementary information in multi-omics data, it is important to have a one-stop-
        shop platform for researchers to integrate multi-omics data, train customized deep-learning models using high-performance computing resources and estimate the potential biomarkers.
        </p>
        <p>The G2PDeep-v2 server is a comprehensive web-based platform powered by deep learning and 
        developed based on our previous works (<a href={paper_url1} target="_blank">paper1</a> and <a href={paper_url2} target="_blank">paper2</a>), providing phenotype prediction and 
        markers discovery for multi-omics data generated by high-throughput techniques in plants, animals, and humans. The server provides multiple services for researchers to create deep-
        learning models through an interactive interface and train these models using automated hyperparameter tuning algorithm on high-performance computing resources. It visualizes results 
        of phenotype and markers predicted by well-trained models. It also provides Gene Set Enrichment Analysis for the significant markers to provide insights into the mechanisms 
        underlying complex diseases and other biological phenomena.</p>
        <p>
        Following is overview of G2PDeep-v2:
        </p>
        <p>
          <img src={overview_img} style={{ marginLeft: '20%' , width: '40%', height: '40%' }}></img>
        </p>
      </div>
      <div id='step_content_datasets' style={{ display: activeStep === 1 ? 'block' : 'none' }}>
        {/* <img src={workflow_datasets} style={{ width: '100%' }}></img> */}
        <h4 style={{ paddingTop: '20px', paddingBottom: '10px', fontWeight: 'bold' }} >Datasets</h4>
        <p>
        G2PDeep-v2 allows users to create datasets with two options: uploading a comma-separated values (CSV) file or transferring data from a link. For a small dataset (up to 50 MB),
         users can create a dataset by uploading their own data from their local machine. For a large dataset (up to 10 GB), users can enter a shared link of data from Google Drive,
          OneDrive, CyVerse Data Store, and other public repositories. Users can upload multi-omics data, including gene expression, miRNA expression, DNA methylation, protein 
          expression, SNP and CNV. Once the files are uploaded, G2PDeep-v2 performs z-score normalization for each sample and imputes missing values automatically. To merge 
          multi-omics data from various sources, it requires data containing a column with unique IDs for each sample. By combining data from multiple sources, users can create more 
          comprehensive datasets that may be better suited to their research questions. Users can also enter the type of data source to indicate the dataset is human, animal or crop. 
        </p>
        <p>The G2PDeep-v2 validates uploaded files to guarantee the data can be created correctly. For any invalid format or unsupported data, it has a function to stop data creation 
          and show a corresponding error message. It also shows a progress bar with duration and percentage, allowing users to monitor the status of the dataset creation. The created 
          datasets are private and only retrievable by the owners of the datasets. G2PDeep-v2 supports sharing data with the public in anonymized form by removing identifying 
          information of samples, making it available to other researchers to work on data and share insights while protecting dataset privacy. </p>
        <p>
        G2PDeep-v2 integrates the publicly available datasets, such as 23 TCGA datasets, Juan’s rice dataset, SoyNAM datasets and Bandillo's SNP datasets. 
        </p>
      </div>
      <div id='step_content_experiments' style={{ display: activeStep === 2 ? 'block' : 'none' }}>
        {/* <img src={workflow_experiment} style={{ width: '100%' }}></img> */}
        <h4 style={{ paddingTop: '20px', paddingBottom: '10px', fontWeight: 'bold' }} >Models</h4>
        <p>
        Customizing the model is a key feature of G2PDeep-v2, as hyperparameters are critical components of machine learning models that influence their performance. On the Model 
        Creation page, users can customize the model by selecting specific hyperparameters and training parameters for the models. Users can also select up to three different types 
        of data as input and can determine whether the model is designed for quantitative phenotype prediction or categorical phenotype prediction. 
        </p>
        <p>
        To balance the speed of training and the performance of model, G2PDeep-v2 provides three strategies for setting hyperparameters. 
        </p>
        <ul>
          <li>The first strategy is to use default hyperparameters, which are pre-tuned models created using data from 23 different TCGA studies, allowing users to quickly create models without the need for additional tuning. </li>
          <li>The second strategy is to customize hyperparameters through an interactive interface, enabling users to tailor their models to specific datasets and research questions. </li>
          <li>The third strategy is an automated hyperparameter search, which uses a Bayesian hyperparameter optimization algorithm to efficiently explore a large search space and identify optimal hyperparameters that are difficult to identifiable through manual tuning.</li>
        </ul>
      </div>
      <div id='step_content_experiments' style={{ display: activeStep === 3 ? 'block' : 'none' }}>
        {/* <img src={workflow_experiment} style={{ width: '100%' }}></img> */}
        <h4 style={{ paddingTop: '20px', paddingBottom: '10px', fontWeight: 'bold' }} >Projects</h4>
        <p>
        G2PDeep-v2 provides a series of functions to train a model using updated datasets. On the Project Creation page, users can retrieve all public models and their private models 
        according to the data type of multi-omics data they are interested in. The page then requires users to choose one dataset for each type of multi-omics data as input for the 
        model. After choosing datasets, users can change the strategy for setting hyperparameters, allowing them to try different strategies to find the best option for their data. 
        Once the project is submitted, it is placed in a task queue and waits for computing resources to be allocated. The project settings and model configurations are stored in the 
        database. The server takes an average of 2 hours to train a model with the automated hyperparameter tuning setting using 400 training samples of three types of multi-omics 
        data and CPU resources.
        </p>
      </div>
      <div id='step_content_prediction' style={{ display: activeStep === 4 ? 'block' : 'none' }}>
        {/* <img src={workflow_prediction} style={{ width: '100%' }}></img> */}
        <h4 style={{ paddingTop: '20px', paddingBottom: '10px', fontWeight: 'bold' }} >Prediction & Biomarkers Discovery</h4>
        <p>
        G2PDeep-v2 provides users with the functionality to make predictions and visualize results using multi-omics data and a well-trained model. With the test data from users, 
        G2PDeep-v2 generates a bar chart of predicted values and a plot of significant biomarkers. Users can change the threshold of the number of biomarkers with the highest 
        saliency values to focus on the most relevant biomarkers for their research needs. The significant biomarkers in the plot are sorted by decreasing saliency values. The 
        biomarkers with corresponding saliency values can be saved as a CSV file. G2PDeep-v2 also provides Gene Set Enrichment Analysis (GSEA) for significant biomarkers. It performs 
        GSEA analysis based on KEGG and Reactome pathway databases which are widely used and comprehensive resources for pathway information. In cases where the biomarkers are not 
        genes, such as CpG islands, G2PDeep-v2 converts these markers to the corresponding genes that they regulate. It also provides users with a categorical scatterplot to explore 
        enriched pathways and gene sets, making it easy to gain insights into the molecular mechanisms underlying complex diseases and other biological phenomena. Detailed enriched 
        pathways are also shown in the form of a table, along with corresponding p-values, adjusted p-values, and gene sets. The literature associated with significant biomarkers and 
        cancer studies is listed as a table.
        </p>
      </div>
      <div id='step_content_prediction' style={{ display: activeStep === 5 ? 'block' : 'none' }}>
        {/* <img src={workflow_prediction} style={{ width: '100%' }}></img> */}
        <h4 style={{ paddingTop: '20px', paddingBottom: '10px', fontWeight: 'bold' }} >Study Results</h4>
        <p>
        We studied 23 TCGA cancer studies with six different types of multi-omics data independently. In each study, we tested 41 datasets, comprising 6 single multi-omics data types, 
        15 combinations of two multi-omics data types, and 20 combinations of three multi-omics data types. This enabled a thorough analysis of G2PDeep-v2's efficacy across various 
        multi-omics data combinations, providing valuable insights into its performance in diverse scenarios. We individually tuned hyperparameters for five models, including CNN, SVM, 
        Logistic Regression, Decision Tree, and Random Forest models, using the automated hyperparameter tuning strategy. The model with the highest AUC score was saved to identify 
        significant biomarkers. All significant biomarkers for each study are already integrated in G2PDeep-v2 and can be easily retrieved.
        </p>
      </div>
    </div>
  )
  return (
    <div>
      {/* <h3 style={{ fontSize: '20px', float: 'left' }}> Introduction to G2PDeep</h3> */}
      <div style={{  height: '40%' }}>
        {/* <div style={{ display: 'inline-block', float: 'left' }}>
          <div style={{ backgroundColor: 'lightcoral', width: '100px', height: '10%', display: 'inline-block', margin: '20px' }}></div>
          <div style={{ backgroundColor: 'lightcoral', width: '100px', height: '25px', display: 'inline-block', margin: '20px' }}></div>
          <div style={{ backgroundColor: 'lightcoral', width: '100px', height: '25px', display: 'inline-block', margin: '20px' }}></div>
          <div style={{ backgroundColor: 'lightcoral', width: '100px', height: '25px', display: 'inline-block', margin: '20px' }}></div>
        </div> */}

        <video style={{ width: "100%", height: "250px", objectFit: 'cover' }} loop autoPlay="autoplay" muted >
          <source src={gene_video} type="video/mp4" />
        </video>
      </div>

      <div>
        {/* <Grid> */}
        {step_comp}
        {/* <ListItemIcon> */}
        {/* {step_comp} */}
        {step_content}
        {/* </ListItemIcon> */}

        {/* </Grid> */}
      </div>
    </div >
  )
}
