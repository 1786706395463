import DownloadIcon from '@mui/icons-material/Download';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Link, useNavigate } from "react-router-dom";
export default function Workshop() {

    const onButtonClick1 = () => {
        // using Java Script method to get PDF file
        fetch('G2PDeep_Workshop_Part1.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'G2PDeep_Workshop_Part1.pdf';
                alink.click();
            })
        })
    }

    const onButtonClick2 = () => {
        // using Java Script method to get PDF file
        fetch('G2PDeep_Workshop_Part2.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'G2PDeep_Workshop_Part2.pdf';
                alink.click();
            })
        })
    }

      const onButtonClick3 = () => {
        // using Java Script method to get PDF file
        fetch('G2PDeep_Workshop_Part3.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'G2PDeep_Workshop_Part3.pdf';
                alink.click();
            })
        })
    }
      
    return (
        <>
            <div>
            <h3 style={{
                    textAlign: "justify",
                    fontWeight: 400
                }}> Workshops</h3>
                <div style={{ textAlign: "left" }}>
               <ul>
                    <li><h4><a href="https://iscb.junolive.co/ismb2023/Home" target="_blank">ISMB/ECCB 2023 hybrid conference </a></h4></li>
                    <p><a href="https://iscb.junolive.co/ismb2023/live/breakouts/ismbeccb2023_tutvt4" target="_blank">Tutorial VT4: Deep learning modeling, training, prediction, and marker analysis of Multiomics data using G2PDeep-v2 web server</a></p>
                    <p>July 17th, 7:00 AM - 11:00 AM - Speakers: Trupti Joshi, Shuai Zeng, Ajay Kumar, Manish Sridhar Immadi</p>
               </ul>
               <p>In case you have missed: <a href="https://iscb.junolive.co/ismb2023/library/search/ondemandismbeccb2023_tutvt4" target="_blank">Click here for Workshop Recording <OndemandVideoIcon/></a></p>
               <p><a href="https://docs.google.com/forms/d/e/1FAIpQLSf_0RTzbDDMYLoB6sshSJ_uKtbTrC1xESEknfIexsdboh7lHA/viewform?pli=1" target="_blank">ISMB/ECCB 2023 Tutorial Feedback</a></p>
               <h3 style={{
                    textAlign: "justify",
                    fontWeight: 400
                }}> Documentation</h3>
                <p><a href="https://mailmissouri-my.sharepoint.com/:v:/g/personal/akt5b_umsystem_edu/Eb7Rg4HYHgtDqPBpaPtt5AIBhXXM5dT4j63qDX4LnQQ5YQ?e=XHpkJg" target="_blank">Click here for Tutorial Video <OndemandVideoIcon/></a></p>
                <button onClick={onButtonClick1} style={{
                    backgroundColor: 'rgb(42, 63, 84)',
                    marginTop: '10px',
                    position: 'absolute',
                    left: 15,
                    color: 'white',
                    borderRadius: '5px'
                }}>
                 Documentation Part 1 <DownloadIcon/>
                </button>
                <button onClick={onButtonClick2} style={{
                    backgroundColor: 'rgb(42, 63, 84)',
                    marginTop: '50px',
                    position: 'absolute',
                    left: 15,
                    color: 'white',
                    borderRadius: '5px'
                }}>
                 Documentation Part 2 <DownloadIcon/>
                </button>
                <button onClick={onButtonClick3} style={{
                    backgroundColor: 'rgb(42, 63, 84)',
                    marginTop: '90px',
                    marginBottom: '20px',
                    position: 'absolute',
                    left: 15,
                    color: 'white',
                    borderRadius: '5px'
                }}>
                 Documentation Part 3 <DownloadIcon/>
                </button>
            </div>
            </div>
        </>
    )
}
