export function ExtractDatasetDataToDisplay(rowData) {
    return {
        'Dataset Name': rowData.dataset_name,
        'Training Dataset Url': rowData.training_dataset_url,
        'Training Dataset Path': rowData.training_dataset_path,
        'Number of Samples': rowData.num_samples,
        'Number of Features': rowData.num_features,
        'Dataset Type': rowData.dataset_type_name,
        'Dataset Source': rowData.datasource_type,
        'Training Dataset Ratio': rowData.ratio_training_dataset,
        'Validation Dataset Ratio': rowData.ratio_validation_dataset,
        'Label Type': rowData.label_type_name,
        'Training Dataset Size': rowData.size_traning_dataset,
        'Validation Dataset Size': rowData.size_validation_dataset,
        'Tags': rowData.tags,
        'Dataset Description': rowData.dataset_description,
        'Created Date': rowData.created_at,
        'Updated Date': rowData.updated_at,
        'Dataset id': rowData.dataset_info_id,
    };
}

export function ExtractModelDataToDisplay(rowData) {
    return {
        'Model name': rowData.model_name,
        'Dataset Type': rowData.dataset_type_name_vlsv,
        'Label Type': rowData.label_type_name,
        'ML Model Type': rowData.ml_model_type,
        'Hyperparameter Mode': rowData.ml_model_hparam_mode_name,
        'Hyperparameters': rowData.model_hyperparameters,
        'Architecture Plot URL':rowData.model_architecture_plot_url,
        'Description': rowData.description,
        'Created Date': rowData.created_at,
        'Model id': rowData.model_info_id,
    };
}