import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography, Slider, Divider, CircularProgress } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import Plot from 'react-plotly.js';
import { useLocation } from "react-router";
import { logoutFakeUser } from '../Auth/logoutFakeUser';
import ValueTable from '../StudyResults/ValueTable'
import { checkAuth } from '../Auth/checkAuth';
import { useNavigate } from 'react-router';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';
import { Backdrop } from '@mui/material';

export default function PredictionInfo({ setToken }) {

  //  let data = useLocation();

    const navigate = useNavigate();

    const [significanceValues, setSignificanceValues] = useState([]);
    const [significanceValuesStatus, setSignificanceValuesStatus] = useState("");
    const [predictionValues, setPredictionValues] = useState([]);
    const [predictionValuesStatus, setPredictionValuesStatus] = useState("");
    const [gseaValues, setGseaValues] = useState([]);
    const [gseaValuesStatus, setGseaValuesStatus] = useState("");
    var timeout;
    var timeoutGsea;

    const location = useLocation();
    const pred_id = location.state?.row.prediciton_info_id;

    const marks = [
        {
            value: 0,
            label: '0',
        },
        {
            value: 10,
            label: '10',
        },
        {
            value: 20,
            label: '20',
        },
        {
            value: 30,
            label: '30',
        }, {
            value: 40,
            label: '40',
        },
        {
            value: 50,
            label: '50',
        },
        {
            value: 60,
            label: '60',
        }, {
            value: 70,
            label: '70',
        }, {
            value: 80,
            label: '80',
        }, {
            value: 90,
            label: '90',
        }, {
            value: 100,
            label: '100',
        },
    ];


    const token = sessionStorage.getItem("token");

    const checkAuthentication = checkAuth({ token, setToken, navigate })

    useEffect(() => {

        checkAuthentication()
            .then(result => {
                getPredictedValues();
            });

    }, [])


    useEffect(() => {
        getFeatureSignificanceValues(1, 1);
    }, [])

    useEffect(() => {
        getFeatureGSEAValues(1, 1);
    }, [])

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const [dataLoading, setDataLoading] = useState(false);
  
    const handleDeleteIconClick = () => {
      setShowConfirmationDialog(true);
    };

    const handleConfirmDelete = () => {

        setDataLoading(true);
       
        let myHeaders = new Headers();
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
       
        setTimeout(() => {

            var raw = JSON.stringify({
              "prediciton_info_id": pred_id
          });
        
            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow',
          };
               
          fetch('/api/operation/delete_prediction/', requestOptions)
              .then(res => res.json())
              .then(result => {
                {
                    setDataLoading(false);
                }
            })
              .catch(reqErr => console.error(reqErr));
    
              navigate('/predict');
            //  window.location.reload();
      }, 5000);
        
        setShowConfirmationDialog(false);
      };
    
      const handleCancelDelete = () => {
        // Close the confirmation dialog
        setShowConfirmationDialog(false);
      };

    const getPredictedValues = () => {

        // fetch(`/api/operation/fetch_predicted_values/?prediciton_info_id=${data.state.row.prediciton_info_id}`, {
        fetch(`/api/operation/fetch_predicted_values/?prediciton_info_id=${pred_id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })
            .then(res => res.json())
            .then(data => {
                setPredictionValues(data.message);
                setPredictionValuesStatus(data.status)
                return data;
            })
    }

    const getFeatureSignificanceValues = (id, percentage) => {
        setSignificanceValues([]);
        setSignificanceValuesStatus("");
        fetch(`/api/operation/fetch_feature_significance_values/?prediciton_info_id=${pred_id}&feature_percentage=${percentage}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })
            .then(res => res.json())
            .then(data => {
                setSignificanceValues(data.message);
                setSignificanceValuesStatus(data.status)
                return data;
            })
    }

    const getFeatureGSEAValues = (id, percentage) => {
        setGseaValues([]);
        setGseaValuesStatus("");

        fetch(`/api/operation/fetch_top_significant_features_gsea/?prediciton_info_id=${pred_id}&feature_percentage=${percentage}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })
            .then(res => res.json())
            .then(data => {
                setGseaValues(data.message);
                setGseaValuesStatus(data.status)
                return data;
            })
    }

    const updateSignificanceValue = (event, newValue) => {
        getFeatureSignificanceValues(1, newValue);
        getFeatureGSEAValues(1, newValue);

        // timeout && clearTimeout(timeout);
        // timeout = setTimeout(() => {
        //     getFeatureSignificanceValues(1, newValue);
        // }, 500);

        // timeoutGsea && clearTimeout(timeoutGsea);
        // timeoutGsea = setTimeout(() => {
        //     getFeatureGSEAValues(1, newValue);
        // }, 500);
    }

    return (
        <>
            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        backgroundColor: '#f2f5fa'
                    }}>
                    <Typography>{predictionValues.GROUP_NAME == null ? ("Results") : (predictionValues.GROUP_NAME)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {predictionValues.GROUP_NAME == null ? (<>
                        <CircularProgress color="inherit" />
                        <p>Please wait while loading...!!</p>
                    </>) : (<>
                        {predictionValuesStatus == "SUCCESS" ? (predictionValues.ATTRIBUTES.map((prediction, index) => {
                            return ((prediction.AVAILABLE) ? (< div key={index}>
                                <Plot
                                    data={JSON.parse(prediction.PLOTLY_FIGURE_JSON).data}
                                    layout={JSON.parse(prediction.PLOTLY_FIGURE_JSON).layout}
                                />
                                <br></br>
                                <CSVLink data={prediction.VALUES}>Download Prediction Results</CSVLink>
                            </div>) : (<div key={index}><p style={{ fontWeight: 'bold' }}> {prediction.ATTRIBUTE_NAME}</p>
                                <p> {prediction.NOTICE}</p> </div>
                            ))
                        })) : (<p>{predictionValues}</p>)}
                    </>)}
                </AccordionDetails>
            </Accordion>

            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        backgroundColor: "#dae7f5"
                    }}
                >
                    <Typography>{significanceValues.GROUP_NAME == null ? ("Results") : (significanceValues.GROUP_NAME)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <Typography> Please select the Feature Percentage</Typography>
                        <Slider
                            name='percentage'
                            style={{ color: '#1165f1', width: '30%' }}
                            defaultValue={1}
                            aria-labelledby="discrete-slider-small-steps"
                            min={1} max={100} step={1}
                            valueLabelDisplay="on"
                            marks={marks}
                            onChange={updateSignificanceValue}
                        />
                    </div>

                    {significanceValues.GROUP_NAME == null ? (<>
                        <CircularProgress color="inherit" />
                        <p>Please wait while loading...!!</p>
                    </>) : (<>
                        {
                            significanceValuesStatus == "SUCCESS" ? (significanceValues.ATTRIBUTES.map((value, index) => {
                                return ((value.AVAILABLE) ? (<div key={index}>
                                    <Plot
                                        data={JSON.parse(value.PLOTLY_FIGURE_JSON).data}
                                        layout={JSON.parse(value.PLOTLY_FIGURE_JSON).layout}
                                    />
                                    <br></br>
                                    <CSVLink data={value.VALUES}>Download Results</CSVLink>
                                </div>) : (<div key={index}><p style={{ fontWeight: 'bold' }}> {value.ATTRIBUTE_NAME}</p>
                                    <p> {value.NOTICE}</p> </div>
                                )
                                )
                            })) : (<p>{significanceValues}</p>)
                        }

                    </>)}

                </AccordionDetails>
            </Accordion>

            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        backgroundColor: '#f2f5fa'
                    }}>

                    <Typography>{gseaValues.GROUP_NAME == null ? ("Results") : (gseaValues.GROUP_NAME)}</Typography>
                </AccordionSummary>


                <AccordionDetails>

                    {gseaValues.GROUP_NAME == null ? (<>
                        <CircularProgress color="inherit" />
                        <p>Please wait while loading...!!</p>
                    </>) : (<>
                        {
                            gseaValuesStatus == "SUCCESS" ? (gseaValues.ATTRIBUTES.map((val, index) => {
                                return (<div key={index}>
                                    <h3 style={{
                                        textDecoration: 'underline'
                                    }}> {val.ATTRIBUTE_NAME}</h3>

                                    <div >
                                        {val.IMAGE_BASE64 == null ? (<p style={{ color: 'red' }}>Image not available yet</p>) : (<img style={{
                                            width: '800',
                                            height: '600px',
                                            maxWidth: '100%'
                                        }}
                                            src={`data:image/jpeg;base64,${val.IMAGE_BASE64}`} />)}
                                        <br></br><br></br>
                                    </div>
                                    <div>
                                        {(val.AVAILABLE) ? (<div>
                                            <ValueTable data={val.TABLE_RECORDS} />
                                            <br></br><br></br>

                                        </div>) : (<>
                                            <p> {val.NOTICE}</p> </>
                                        )}
                                    </div>

                                    <Divider />
                                </div>)
                            })) : (<p>{gseaValues}</p>)
                        }
                    </>)}


                </AccordionDetails>
            </Accordion>
            <Dialog open={showConfirmationDialog}>
                  <DialogTitle>Confirm Delete</DialogTitle>
                  <DialogContent>
                    <Typography>Are you sure you want to delete this Prediction?</Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleConfirmDelete} color="secondary">
                      Delete
                    </Button>
                    <Button onClick={handleCancelDelete} color="primary">
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
            <button id={pred_id} type='button' onClick={handleDeleteIconClick} style={{
                    backgroundColor: 'rgb(42, 63, 84)',
                    marginTop: '20px',
                    position: 'absolute',
                    left: 15,
                    color: 'white',
                    borderRadius: '5px'
                }}>Delete Prediction</button>
                        
                {dataLoading ? (<div><Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={dataLoading}
                >
                    <CircularProgress color="inherit" />
                    <Typography> Please donot refresh while deleting</Typography>
                </Backdrop></div>) : ''}
        </>
    )
}
