import UpSetJS, { extractCombinations, extractSets, generateDistinctOverlapFunction } from '@upsetjs/react';
import { useEffect, useMemo } from 'react';

export default function UpsetPlotUtil({ elems, selection, setSelection }) {
    const { sets, combinations } = useMemo(() => extractCombinations(elems, {type: 'distinctIntersection'}), [elems]);

const UpSetSelection = (props) => {

    return <UpSetJS {...props} selection={selection} onClick={setSelection} />
};

return (

    <div>

        <UpSetSelection sets={sets} combinations={combinations} width={1000} height={600} />

    </div>
)
}
