import React from 'react'

// export default function GitHub() {
//   return (
//     <div>
//             <h3 style={{
//                     textAlign: "justify"
//                 }}> GitHub Repos</h3>
//                 <div style={{ textAlign: "justify" }}>
//                     <ul>
//                     <li>G2PDeep V2 - Updating soon.</li>
//                     <li>Click here to access <a href='https://github.com/shuaizengMU/G2PDeep_model' target="_blank">GitHub Repo</a> for G2PDeep V1.</li>
//                     </ul>
//                 </div>
//         </div>

//   )
// }
export default function GitHub() {
  // Redirect to the GitHub link
  React.useEffect(() => {
    window.open('https://github.com/shuaizengMU/G2PDeep_model', '_blank');
  }, []);

  // Render nothing
  return null;
}
